<template>
  <div>
    <div class="overlay" @click="$emit('cancel')" />
    <div class="modal">
      <div class="card modal-card">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  z-index: 11;

  .modal-card {
    overflow: hidden;
    padding: 20px 30px;
    background: var(--accent-color);
  }
}
</style>
