<template>
  <div class="alert">
    <div class="clearfix data">
      <div class="alert-date">{{ $date(alert.createdAt).fromNow() }}</div>
      <div class="alert-name" :class="{ 'not-muted': !alert.muted }">
        {{ alert.alert.name }}
      </div>
      <div class="alert-target">{{ alert.device.name }}</div>
    </div>
    <div class="actions">
      <div class="action action-info">
        <a @click="moreInfo()">More info</a>
      </div>
      <div class="action action-read">
        <a @click="markAsRead()" v-if="!alert.muted">Mark as read</a>
        <a @click="markAsUnRead()" v-else>Mark as unread</a>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils';

export default {
  name: 'Notification',
  props: ['alert'],
  methods: {
    moreInfo() {
      this.$router.push(`/alerts?view=${this.alert._id}`);
      this.$root.$emit('closeAlerts');
    },
    async markAsRead() {
      await Utils.fetch(
        '/api/v1/alerts/triggers/mark',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: this.alert._id,
          }),
        },
        this,
      )
        .then((res) => res.json())
        .catch((error) => {
          console.error(error.response);
        });
      this.$emit('change');
      this.$root.$emit('alertChange');
    },
    async markAsUnRead() {
      await Utils.fetch(
        '/api/v1/alerts/triggers/unmark',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: this.alert._id,
          }),
        },
        this,
      )
        .then((res) => res.json())
        .catch((error) => {
          console.error(error.response);
        });
      this.$emit('change');
      this.$root.$emit('alertChange');
    },
  },
};
</script>

<style scoped lang="scss">
.alert {
  padding: 5px 8px;
  position: relative;
  transition: 0.2s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  .data {
    transition: 0.2s;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);

    .data {
      filter: blur(4px);
      opacity: 0.6;
    }

    .actions {
      opacity: 1;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}
.alert-date {
  float: right;
}
.alert-name {
  color: white;
  display: inline-block;
  position: relative;
  v &.not-muted::after {
    content: '';
    display: block;
    position: absolute;
    height: 6px;
    width: 6px;
    background: red;
    border-radius: 100%;
    top: 3px;
    right: -12px;
    border: 2px solid #343a43;
  }
}
.alert-target {
  padding-top: 3px;
}

.actions {
  transition: 0.2s;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: table;

  .action {
    width: 50%;
    text-align: center;
    display: table-cell;
    vertical-align: middle;

    a {
      cursor: pointer;
    }

    &.action-info {
      left: 0;
    }
    &.action-read {
      left: 50%;
    }
  }
}
</style>
