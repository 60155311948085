import Vue from 'vue';

import path from 'path';

const BaseName = (fn) => path.basename(fn);

const DirName = (fn) => path.dirname(fn);

/**
 * Usage: in vue file : {{ path | basename }}
 */
Vue.filter('basename', BaseName);
Vue.filter('dirname', DirName);

/**
 * Usage:  {{ $basename(filename) }}
 * Usage:  const n = this.$basename('filename')
 */
Object.defineProperties(Vue.prototype, {
  $basename: {
    get() {
      return BaseName;
    },
  },
  $dirname: {
    get() {
      return DirName;
    },
  },
});
