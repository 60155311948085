

































import Chart from 'chart.js';
import Utils from '@/utils';
import Events from '@/components/Table/Events.vue';
// eslint-disable-next-line object-curly-newline
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Dayjs } from 'dayjs';
import Card from '../Card/Card.vue';
import BaseComponent from '../Base.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import DateRangePicker from '../DateRangePicker/DateRangePicker.vue';

@Component({
  name: 'Graph',
  extends: BaseComponent,
  components: {
    Events,
    Card,
    Grid,
    GridCol,
    BaseButton,
    DateRangePicker,
  },
})
export default class Graph extends Vue {
  $router: any;

  @Prop() readonly deviceId!: string;

  drpFormat = 'YYYY-MM-DD';

  ajaxCompleted = false;

  device: any = null;

  name = '';

  ctxBar?: CanvasRenderingContext2D;

  chartBar?: Chart = undefined;

  ctxPie?: CanvasRenderingContext2D;

  chartPie?: Chart = undefined;

  dateFrom = null as string | null;

  dateTo = null as string | null;

  filterHost = null;

  lastEvents = null;

  deviceMac = null;

  datePickerOptions = {
    ranges: [
      {
        key: 'Yesterday',
        values: [this.$date().subtract(1, 'days'), this.$date().subtract(1, 'days')],
      },
      {
        key: 'This week',
        values: [this.$date().startOf('week'), this.$date().endOf('week')],
      },
      {
        key: 'Last 7 Days',
        values: [this.$date().subtract(6, 'days'), this.$date()],
      },
      {
        key: 'Last week',
        values: [
          this.$date().startOf('week').subtract(7, 'days'),
          this.$date().endOf('week').subtract(7, 'days'),
        ],
      },
      {
        key: 'Last 30 Days',
        values: [this.$date().subtract(29, 'days'), this.$date()],
      },
      {
        key: 'This Month',
        values: [this.$date().startOf('month'), this.$date().endOf('month')],
      },
      {
        key: 'Last Month',
        values: [
          this.$date().subtract(1, 'month').startOf('month'),
          this.$date().subtract(1, 'month').endOf('month'),
        ],
      },
    ],
    opens: 'right',
  };

  @Watch('deviceId')
  deviceIdChanged(newVal?: string) {
    if (newVal) {
      this.getDevice(newVal);
    }
  }

  dateToChanged(newVal: string) {
    if (newVal) {
      this.dateTo = newVal;
      this.refreshGraphs();
    }
  }

  dateFromChanged(newVal: string) {
    this.dateFrom = newVal;
    this.refreshGraphs();
  }

  updateRanges(start: Dayjs, end: Dayjs) {
    this.dateFrom = start.format(this.drpFormat);
    this.dateTo = end.format(this.drpFormat);
  }

  cancel() {
    this.$router.push(`/devices/approved/${this.deviceId}`);
  }

  async getDevice(id: string) {
    const response = await Utils.fetch(`/api/v1/devices/${id}`, {}, this).then((res) =>
      res!.json(),
    );
    if (response.success) {
      this.ajaxCompleted = true;
      this.device = response.device;
      this.name = response.device.name;
    }
  }

  getFilter(start: string, end: string, postProcess: string) {
    const filterSource = {
      deviceMac: this.deviceMac,
      placeDown: 1,
      start: start.toString(),
      end: end.toString(),
      postProcess,
      theme: 'brown',
    };
    return Buffer.from(JSON.stringify(filterSource)).toString('base64');
  }

  async refreshBars(start: string, end: string) {
    if (this.filterHost) {
      const filter = this.getFilter(start, end, 'resultsStackedBar');
      const res = await Utils.fetchRaw(`${this.filterHost}/stats/${filter}`, {});
      if (res && res.success) {
        if (this.chartBar) {
          this.chartBar.data = res.chartdata;
          this.chartBar.update();
        } else if (this.ctxBar) {
          this.chartBar = new Chart(this.ctxBar, {
            type: 'bar',
            data: res.chartdata,
            options: {
              title: {
                display: false,
                text: '',
              },
              tooltips: {
                mode: 'index',
                intersect: false,
              },
              responsive: true,
              scales: {
                xAxes: [
                  {
                    stacked: true,
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                  },
                ],
              },
            },
          });
        }
      }
    }
  }

  async refreshPie(start: string, end: string) {
    if (this.filterHost) {
      const filter = this.getFilter(start, end, 'resultsPieChart');
      const res = await Utils.fetchRaw(`${this.filterHost}/stats/${filter}`, {});
      if (res && res.success) {
        if (this.chartPie) {
          this.chartPie.data = res.chartdata;
          this.chartPie.update();
        } else if (this.ctxPie) {
          this.chartPie = new Chart(this.ctxPie, {
            type: 'pie',
            data: res.chartdata,
            options: {
              title: {
                display: false,
                text: '',
              },
              tooltips: {
                mode: 'index',
                intersect: false,
              },
              responsive: true,
              scales: {
                xAxes: [
                  {
                    stacked: true,
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                  },
                ],
              },
            },
          });
        }
      }
    }
  }

  async refreshGraphs() {
    if (this.dateFrom && this.dateTo) {
      this.refreshBars(this.dateFrom, this.dateTo);
      this.refreshPie(this.dateFrom, this.dateTo);
    }
  }

  async setupChart() {
    const barChartCanvas = document.getElementById('deviceChartBar') as HTMLCanvasElement;
    const barChartContext = barChartCanvas.getContext('2d');
    if (barChartContext) {
      this.ctxBar = barChartContext;
    }

    const pieChartCanvas = document.getElementById('deviceChartPie') as HTMLCanvasElement;
    const pieChartContext = pieChartCanvas.getContext('2d');
    if (pieChartContext) {
      this.ctxPie = pieChartContext;
    }
  }

  async setupDatepicker() {
    // eslint-disable-next-line prefer-destructuring
    this.dateFrom = this.$date().startOf('month').format(this.drpFormat);
    // eslint-disable-next-line prefer-destructuring
    this.dateTo = this.$date().endOf('month').format(this.drpFormat);
    this.refreshGraphs();
  }

  mounted() {
    this.getDevice(this.deviceId).then(() => {
      this.filterHost = this.device.organization.logs;
      this.deviceMac = this.device.system.macAddress;
      this.setupChart();
      this.setupDatepicker();
    });
  }
}
