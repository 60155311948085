








import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import Theme from '../../models/enums/Theme';
import { ButtonIconType, ButtonType } from './ButtonTypes';

@Component({
  name: 'BaseButton',
  computed: {
    ...mapGetters('theme', { selectedTheme: 'selectedTheme' }),
  },
})
export default class BaseButton extends Vue {
  selectedTheme!: Theme;

  @Prop({ default: '' }) readonly title!: string;

  @Prop({ default: '' }) readonly type!: ButtonType;

  @Prop({ default: '' }) readonly iconType!: ButtonIconType;

  @Prop({ default: false }) readonly isSmall!: boolean;

  @Prop({ default: false }) readonly isDisabled!: boolean;

  get computedClasses() {
    const classArr = [];
    if (this.isSmall) {
      classArr.push('small');
    }
    switch (this.type) {
      case ButtonType.Delete:
        classArr.push('delete');
        break;
      case ButtonType.Add:
        classArr.push('add');
        break;
      case ButtonType.Create:
        classArr.push('create');
        break;
      case ButtonType.Cancel:
        classArr.push('cancel');
        break;
      case ButtonType.Warning:
        classArr.push('warning');
        break;
      case ButtonType.Themed:
        classArr.push(this.selectedTheme === Theme.Dark ? 'grey-button' : 'light-button');
        break;
      case ButtonType.ThemedDelete:
        classArr.push(this.selectedTheme === Theme.Dark ? 'grey-button' : 'light-button');
        classArr.push('themed-delete');
        break;
      default:
        break;
    }
    return classArr;
  }

  get iconClass() {
    switch (this.iconType) {
      case ButtonIconType.Cancel:
        return 'uil uil-times-circle';
      case ButtonIconType.Check:
        return 'uil uil-check';
      case ButtonIconType.Plus:
        return 'uil uil-plus';
      case ButtonIconType.Edit:
        return 'uil uil-edit';
      case ButtonIconType.Trash:
        return 'uil uil-trash-alt';
      case ButtonIconType.Download:
        return 'uil uil-download';
      case ButtonIconType.Save:
        return 'uil uil-save';
      case ButtonIconType.Back:
        return 'uil uil-backward';
      case ButtonIconType.Forward:
        return 'uil uil-forward';
      case ButtonIconType.Sync:
        return 'uil uil-sync';
      default:
        return '';
    }
  }
}
