<template>
  <div>
    <card v-if="ready">
      <div class="card-title">
        <div class="left">Timer Preview</div>
        <div class="local-utc-switch right">
          {{ device.magicinfo.stats.TZ }}
          <label class="switch" v-bind:class="{ open: useUTC }">
            <input type="checkbox" v-model="useUTC" />
            <span class="slider round" />
          </label>
          UTC
        </div>
      </div>
      <div class="card-body">
        <br />
        <table class="timer-grid">
          <tr>
            <td />
            <td v-for="h in labels.hours" :key="h.hour">{{ h.hour }}</td>
          </tr>
          <tr v-for="d in labels.days" :key="d">
            <td>{{ mapping.days[d] }}</td>
            <td
              v-for="h in labels.hours"
              :key="h.hour"
              class="hour"
              :class="hours[d][h.hour] !== undefined ? 'on' : 'off'"
            >
              &nbsp;
            </td>
          </tr>
        </table>
      </div>
    </card>
  </div>
</template>

<script>
import Card from '../Card/Card.vue';

export default {
  name: 'MagicinfoTimerGrid',
  props: ['device'],
  components: { Card },
  data() {
    return {
      ready: false,
      useUTC: false, // use local time = false, so use utc
      mapping: {
        days: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        hours: [],
      },
      labels: {
        days: Array.from({ length: 7 }, (v, k) => k),
        hours: Array.from({ length: 24 }, (v, k) => k).map((h) => ({ hour: h, status: 'off' })),
      },
    };
  },
  computed: {
    hours() {
      const hours = [];
      // which timers to use
      if (typeof this.device.system.timers !== 'undefined' && this.device.system.timers) {
        const Timers = this.useUTC
          ? this.device.system.timers.utc
          : this.device.system.timers.local;
        Timers.forEach((daytimers, index) => {
          hours[index] = [];
          daytimers.forEach((daytimer) => {
            if (daytimer.status === 'on') {
              const starthour = Math.floor(daytimer.start / 60);
              const endhour = Math.floor((daytimer.end - 60) / 60);
              for (let i = starthour; i <= endhour; i += 1) {
                hours[index][i] = true;
              }
            }
          });
        });
      }
      return hours;
    },
  },
  watch: {
    'device._id': {
      handler() {
        this.ready = this.hasHoursOn();
      },
    },
  },
  methods: {
    hasHoursOn() {
      return this.hours.filter((m) => m.length > 0).length > 0;
    },
  },
  mounted() {
    this.ready = this.hasHoursOn();
  },
};
</script>

<style lang="scss" scoped>
table.timer-grid {
  border-collapse: collapse;
  width: 100%;
}
table.timer-grid td {
  border: 2px solid #3a4149;
  text-align: center;
}
table.timer-grid tr:first-child td,
table.timer-grid td:first-child {
  border: 0;
  color: #d2d2d2;
  font-size: 90%;
}
table.timer-grid td:first-child {
  text-align: center;
  vertical-align: center;
}
table.timer-grid td.hour {
  height: 20px;
  width: 4%;
}
table.timer-grid td.on {
  background: rgb(77, 189, 116);
}
table.timer-grid td.off {
  background: rgba(0, 0, 0, 0.4);
}
.local-utc-switch {
  color: #bcbcbc;
  font-size: 10pt;
}
.card-body {
  padding: 10px 0 0 0;
}
</style>
