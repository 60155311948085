<template>
  <div class="row">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Grid',
};
</script>

<style scoped lang="scss">
.row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 18px;
}
</style>
