<template>
  <login-box :isError="error" :isBusy="busy" v-on:handleSubmit="handleSubmit" />
</template>
<script>
import LoginBox from '../components/LoginBox/LoginBox.vue';

export default {
  components: {
    LoginBox,
  },
  data() {
    return {
      error: false,
      busy: false,
    };
  },
  methods: {
    async handleSubmit(login, password) {
      if (password.length > 0) {
        if (!this.busy) {
          this.busy = true;
          try {
            const response = await this.$utils.http().post('/api/v1/auth/login', {
              login,
              password,
            });

            this.$router.push({
              name: '2FA',
              query: {
                ss: response.data.twoFactorSession,
                ft: response.data.firstTime,
                nextUrl: this.$route.query.nextUrl,
              },
            });
            return;
          } catch (err) {
            this.error = true;
          }
          this.busy = false;
        }
      }
    },
  },
};
</script>
