<template>
  <div class="card notice" v-if="showMessage">
    <div class="card-title">Warning</div>
    <div style="margin-top: 10px">
      Your outgoing IP address doesn't seem to have access to the remote desktop server. Please
      contact your administator if you need remote desktop access.
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoMachineStatus',
  props: ['proxy'],
  data() {
    return {
      showMessage: false,
    };
  },
  methods: {
    async getNoMachineProxyStatus() {
      fetch(`https://${this.proxy}:25000/`)
        .then((response) =>
          // console.log(response);
          response.json(),
        )
        .then(() => {
          this.showMessage = false;
        })
        .catch(() => {
          this.showMessage = true;
        });
    },
  },
  mounted() {
    this.getNoMachineProxyStatus();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
