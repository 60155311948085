<template>
  <div class="card">
    <h4 v-if="title" class="card-title">
      {{ title }}
    </h4>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: ['title'],
};
</script>

<style scoped lang="scss">
h1,
h2,
h3,
h4 {
  margin-top: 0;
}

.card {
  background: var(--accent-color);
  padding: 16px;
  border: var(--card-border);
  box-shadow: var(--card-shadow);
  border-radius: 3px;
  margin-bottom: 18px;
  width: 100%;
  box-sizing: border-box;
}

h4 {
  color: var(--header-font-color);
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
}

.card-body {
  padding: 5px;
}
</style>
