<template>
  <div class="migration-table">
    <div v-if="ajaxCompleted && !hasCommands">
      <h3>There are no scripts available for the OS '{{ device.system.os }}'</h3>
    </div>
    <div v-else>
      <table>
        <thead>
          <tr>
            <th>Script Name</th>
            <th>Status</th>
            <th>Success</th>
            <th v-if="canEditCommands" />
          </tr>
        </thead>
        <tbody v-if="ajaxCompleted">
          <tr
            v-for="command in commandsDone"
            :key="command._id"
            :class="{ disabled: command.disabled }"
          >
            <td>
              <span v-if="command.script !== null" @click="showOutputModal(command._id)">{{
                command.script.description
              }}</span>
              <span v-else>unknown script</span>
            </td>
            <td>
              <span v-if="command.completed">
                <i class="uil uil-check-circle migration-status green" /> Complete
              </span>
              <span v-else-if="command.disabled"> Disabled </span>
              <span v-else>
                <i class="uil uil-question-circle migration-status orange" /> Pending
              </span>
            </td>
            <td>
              <i
                v-if="
                  command.success !== null &&
                  typeof command.success !== 'undefined' &&
                  command.success === true
                "
                class="uil uil-check-circle migration-status green"
              />
              <i
                v-else-if="
                  command.success !== null &&
                  typeof command.success !== 'undefined' &&
                  command.success === false
                "
                class="uil uil-times-circle migration-status red"
              />
              <span v-else>-</span>
            </td>
            <td v-if="canEditCommands">
              <a
                v-if="
                  (command.completed === null || typeof command.completed === 'undefined') &&
                  !command.disabled
                "
                class="link"
                @click="removeScript(command._id)"
                >Remove script</a
              >
            </td>
          </tr>
          <tr v-for="script in commandsNew" :key="script._id">
            <td>
              {{ script.description }}
            </td>
            <td>-</td>
            <td>-</td>
            <td v-if="canEditCommands">
              <a class="link" @click="runScript(script._id)">Assign</a>
              |
              <a class="link" @click="disableScript(script._id)">Disable</a>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="3" class="loader">
              <img :src="loadingImage" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils';

export default {
  name: 'Migration',
  data() {
    return {
      hasCommands: false,
      commandsDone: [],
      commandsNew: [],
      ajaxCompleted: false,
      commandRuns: false, // Prevents that double click triggers runScript twice
      canEditCommands: false,
    };
  },
  props: ['deviceId', 'device'],
  watch: {
    deviceId() {
      this.loadCommands();
    },
  },
  methods: {
    async showOutputModal(command) {
      const response = await Utils.fetch(
        `/api/v1/devices/${this.deviceId}/command/${command}/output`,
        {},
        this,
      ).then((res) => res.json());
      if (response.success) {
        this.$modal.show('dialog', {
          title: 'Script output',
          text: `<div class='script-dialog'><div class='red'>${response.err.replace(
            /\n/g,
            '<br>',
          )}</div>${response.out.replace(/\n/g, '<br>')}</div>`,
          buttons: [
            {
              title: 'Close',
            },
          ],
        });
      } else {
        this.$noty.warning(response.message);
      }
    },
    hideModal() {
      this.$modal.hide('hello-world');
    },
    async loadCommands() {
      const response = await Utils.fetch(
        `/api/v1/devices/${this.deviceId}/commands`,
        {},
        this,
      ).then((res) => res.json());
      if (response.success) {
        this.commandsDone = response.commandsDone;
        this.commandsNew = response.commandsNew;
        this.ajaxCompleted = true;
        this.commandRuns = false;
        this.hasCommands = this.commandsDone.length || this.commandsNew.length;
      } else {
        this.$noty.warning(response.message);
      }
    },
    async runScript(id) {
      if (!this.commandRuns) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to schedule the script for this device?')) {
          this.commandRuns = true;
          const response = await Utils.fetch(
            `/api/v1/devices/${this.deviceId}/commands`,
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                script: id,
              }),
            },
            this,
          ).then((res) => res.json());

          if (response.success) {
            this.loadCommands();
          } else {
            this.$noty.warning(response.message);
          }
        }
      }
    },

    async disableScript(id) {
      if (!this.commandRuns) {
        if (
          // eslint-disable-next-line no-restricted-globals
          confirm(
            'Do you really want to disable this script for this device? There will be no way to re-enable this script without contacting support.',
          )
        ) {
          this.commandRuns = true;
          const response = await Utils.fetch(
            `/api/v1/devices/${this.deviceId}/commands/disable`,
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                script: id,
              }),
            },
            this,
          ).then((res) => res.json());

          if (response.success) {
            this.loadCommands();
          } else {
            this.$noty.warning(response.message);
          }
        }
      }
    },

    async removeScript(id) {
      if (!this.commandRuns) {
        this.commandRuns = true;
        const response = await Utils.fetch(
          `/api/v1/devices/${this.deviceId}/commands`,
          {
            method: 'DELETE',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              command: id,
            }),
          },
          this,
        ).then((res) => res.json());

        if (response.success) {
          this.loadCommands();
        } else {
          this.$noty.warning(response.message);
        }
      }
    },
  },
  mounted() {
    this.loadCommands();
    if (Utils.userCan('edit', 'command')) {
      this.canEditCommands = true;
    }
  },
};
</script>

<style scoped lang="scss">
.migration-table {
  padding-top: 16px;
}

.migration-table table {
  width: 100%;
  border-collapse: collapse;

  .loader {
    text-align: center;
    padding: 20px 0;

    img {
      height: 30px;
    }
  }

  tr {
    height: 30px;
  }

  tbody tr {
    cursor: pointer;

    &.selected {
      background: var(--table-row-hover-color);
    }

    &:hover {
      background: var(--table-row-hover-color);
    }
  }

  th,
  td {
    padding: 0 10px;
  }

  thead tr th {
    //border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
    background: var(--table-header-color);
  }

  th {
    font-weight: normal;
  }

  tr.disabled {
    opacity: 0.4;
  }

  .migration-status {
    margin-right: 6px;
    transform: translateY(5%);
    display: inline-block;
  }
}
</style>
