






















































































import Utils from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import AlertDropdown from '@/components/AlertManager/AlertDropdown.vue';
import { mapGetters } from 'vuex';
import ThemeSwitch from '../ThemeSwitch/ThemeSwitch.vue';
import Theme from '../../models/enums/Theme';
import BaseLogo from '../BaseLogo/BaseLogo.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

@Component({
  name: 'TheMenu',
  components: {
    AlertDropdown,
    ThemeSwitch,
    BaseLogo,
    BaseButton,
  },
  computed: {
    ...mapGetters('theme', { selectedTheme: 'selectedTheme' }),
  },
})
export default class TheMenu extends Vue {
  selectedTheme!: Theme;

  user = null;

  expandUser = false;

  showDropdown = false;

  hasAlerts = false;

  interval: any;

  tokenInfo: any;

  $utils: any;

  mounted() {
    this.user = Utils.getUser();
    this.$root.$on('login', () => {
      this.user = Utils.getUser();
    });
    this.$root.$on('logout', () => {
      this.user = Utils.getUser();
    });
    this.$root.$on('closeAlerts', () => {
      this.showDropdown = false;
    });

    this.loadTokenInfo().then(() => {
      if (this.tokenNeedsRefreshing()) {
        this.extendLogin();
      }
      this.interval = setInterval(() => {
        if (this.tokenNeedsRefreshing()) {
          this.extendLogin();
        }
      }, 60000);
    });
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }

  // eslint-disable-next-line class-methods-use-this
  userCan(perm: string, resource: string) {
    return Utils.userCan(perm, resource);
  }

  // eslint-disable-next-line class-methods-use-this
  isAdmin() {
    return Utils.isAdmin();
  }

  handleAlerts(hasAlerts: boolean) {
    this.hasAlerts = hasAlerts;
  }

  tokenNeedsRefreshing() {
    const user = Utils.getUser();
    if (user && user.id) {
      // If token expires after 20 minutes and can be renewed after 15 minutes
      // then we have a renew window of 5 minutes - or 300 seconds.
      const tokenRenewWindow = (this.tokenInfo.expire - this.tokenInfo.renew) * 60;

      const nowUnixTS = Date.now() / 1000;
      if (nowUnixTS > user.exp - tokenRenewWindow && nowUnixTS < user.exp) {
        return true;
      }
    }
    return false;
  }

  async extendLogin() {
    const response = await this.$utils.http().get('/api/v1/auth/extend');
    localStorage.setItem('jwt', response.data.token);
  }

  async loadTokenInfo() {
    const response = await this.$utils.http().get('/api/v1/auth/info');
    this.tokenInfo = response.data.info;
  }
}
