// TODO: Linting is disabled in this file, as fixing the linting broke the application
// This  should be fixed in the near future
/* eslint-disable */

import VueRouter, { Route } from "vue-router";

type Context = {
  $route: Route,
  $router: VueRouter,
  filter: any;
}

export default {
  init(context: & Context) {
    let filterHash = null;
    if (context.$route.hash) {
      filterHash = context.$route.hash;
    } else {
      const savedHash = this.loadHash(context);
      if (savedHash !== null) {
        filterHash = savedHash;
        this.pushHash(savedHash, context); // will change location
      }
    }
    if (filterHash !== null) {
      context.filter = this.unserializeFilter(filterHash);
    }
  },
  serializeFilter(filter: any) {
    return btoa(JSON.stringify(filter));
  },
  unserializeFilter(hash: string) {
    return JSON.parse(atob(hash.replace(/#/, '')));
  },
  hashName(context: Context) {
    // /devices/approved -> filterhash-devices-approved
    // /devices/approved/5fc62d707d57c0006b065633 -> filterhash-devicesapproved-5fc62d707d57c0006b065633
    // --> this will remove 5fc62d707d57c0006b065633 since it's the value of a param
    let path = context.$route.path.replace(/^\//, '').split('/');
    if (context.$route.params) {
      for (const param of Object.keys(context.$route.params)) {
        path = path.filter((p) => {
          return p !== context.$route.params[param];
        });
      }
    }
    path.unshift('filterhash');
    return path.join('-');
  },
  saveHash(hash: string, context: Context) {
    return localStorage.setItem(this.hashName(context), hash);
  },
  loadHash(context: Context) {
    return localStorage.getItem(this.hashName(context));
  },
  pushHash(hash: string, context: Context) {
    this.saveHash(hash, context);
    context.$router.replace({ hash: `#${hash}` }).catch(() => {});
  },
  match(devices: any[], filter: any, context: Context) {
    let result = devices.filter((device: any) => {
      const search = filter.search.toLowerCase();

      let res = true;
      if (search) {
        const searchList = search.split(' ');
        for (let i = 0; i < searchList.length; i++) {
          if (res) {
            try {
              res =
                device.name.toLowerCase().includes(searchList[i]) ||
                device.hwUnique.toLowerCase().includes(searchList[i]) ||
                (device.system.externalIp || '').toLowerCase().includes(searchList[i]) ||
                (device.system.internalIp || '').toLowerCase().includes(searchList[i]) ||
                device.system.macAddress.toLowerCase().includes(searchList[i]);
            } catch (e) {
              console.warn([e.message, device]);
            }
          }
        }
      }

      // Online status
      if (filter.online) {
        const onlineStatusWanted = parseInt(filter.online);
        if (onlineStatusWanted > -1) {
          switch (onlineStatusWanted) {
            // Offline
            case 0:
              res = res && !device.online;
              break;
            // Online
            case 1:
              res = res && device.online;
              break;
            default:
              res =
                res && !device.online && device.lastSeen < Date.now() - onlineStatusWanted * 1000;
              break;
          }
        }
      }

      // Should show devices that have never been online?
      if (filter.showUnconfigured === 'hide') {
        res = res && !!device.lastSeen;
      }

      // Alert trigger Status
      switch (parseInt(filter.alert, 10)) {
        case 1:
          res = res && device.hasAlert;
          break;
        case 0:
          res = res && !device.hasAlert;
          break;
        default:
          break;
      }

      // VPN Status
      switch (parseInt(filter.vpn, 10)) {
        case 1:
          res = res && device.vpn && device.vpn.active;
          break;
        case 0:
          res = res && (!device.vpn || !device.vpn.active);
          break;
        default:
          break;
      }

      // MGA Status
      const MGARunningV2 =
        device.stats &&
        device.stats.yamaha &&
        device.stats.yamaha.myGarageApp &&
        device.stats.yamaha.myGarageApp.isRunning;
      switch (parseInt(filter.myGarage.running, 10)) {
        case 1:
          res = res && MGARunningV2 === true;
          break;
        case 0:
          res = res && MGARunningV2 !== true;
          break;
        default:
          break;
      }

      // MGA Version
      if (filter.myGarage.version !== '') {
        let version = device.stats ? device.stats.mgaVersion : null;
        if (!version && device.stats && device.stats.yamaha && device.stats.yamaha.myGarageApp) {
          version = device.stats.yamaha.myGarageApp.version;
        }

        switch (filter.myGarage.match) {
          case '=':
            res = res && device.stats && version === filter.myGarage.version;
            break;
          case '>=':
            if (version) {
              const filterVersion = new Version(filter.myGarage.version);
              const deviceVersion = new Version(version);
              res = res && deviceVersion.isGreaterOrEqualThan(filterVersion);
            } else {
              res = false;
            }
            break;
          case '<=':
            if (version) {
              const filterVersion = new Version(filter.myGarage.version);
              const deviceVersion = new Version(version);
              res = res && deviceVersion.isSmallerOrEqualThan(filterVersion);
            } else {
              res = false;
            }
            break;
          default:
            break;
        }
      }

      // Display Connected Status
      // const displayConnectedV1 = device.stats && device.stats.displays && device.stats.displays !== 'No display connected.';
      const displayConnectedV2 =
        device.stats && device.stats.yamaha && device.stats.yamaha.displayConnected;
      switch (parseInt(filter.displaysConnected, 10)) {
        case 1:
          res = res && displayConnectedV2 === true;
          break;
        case 0:
          res = res && displayConnectedV2 !== true;
          break;
        default:
          break;
      }

      // Has Note
      switch (parseInt(filter.note, 10)) {
        case 1:
          res = res && device.hasNote;
          break;
        case 0:
          res = res && !device.hasNote;
          break;
        default:
          break;
      }

      // Internet speed
      switch (parseInt(filter.internetSpeed, 10)) {
        case -1:
          break;
        default:
          const speedFloat = parseFloat(filter.internetSpeed);
          if (device.stats && device.stats.internetSpeed && device.stats.internetSpeed.current) {
            const avg = device.stats.internetSpeed.current;
            if ((avg.up && avg.up < speedFloat) || (avg.down && avg.down < speedFloat)) {
              res = res && true;
            } else {
              res = false;
            }
          } else {
            res = false;
          }
          break;
      }

      // Country
      if (filter.location.country !== '') {
        res = res && device.autofields && device.autofields.country === filter.location.country;
      }

      // Distributor
      if (filter.location.distri !== '') {
        res = res && device.autofields && device.autofields.distributor === filter.location.distri;
      }

      // Miserver
      if (filter.miserver !== 'all') {
        res = res && device.magicinfo.miserver && device.magicinfo.miserver._id === filter.miserver;
      }

      // Tags
      if (filter.tags.length) {
        if (typeof device.tags !== 'undefined' && device.tags.length) {
          const filterTags = filter.tags.map((e: & {text: string}) => e.text);
          res = res && filterTags.every((tag: string) => device.tags.includes(tag));
        } else {
          res = false;
        }
      }

      // System.Os
      if (filter.system.os !== 'all') {
        res = res && device.system.os === filter.system.os;
      }

      if (typeof filter.version === 'string') {
        const [fld, vrs] = filter.version.split(':');
        switch (fld) {
          case 'clientVersion':
            res = res && device.clientVersion && device.clientVersion == vrs;
            break;
          case 'mgaVersion':
            res =
              res &&
              device.stats &&
              device.stats.mgaVersion &&
              device.stats.mgaVersion.slice(-8) == vrs;
            break;
          default:
            break;
        }
      }

      return res;
    });
    this.pushHash(this.serializeFilter(filter), context);
    return result;
  },
  empty() {
    return {
      search: '',
      online: -1,
      alert: -1,
      version: -1,
      showUnconfigured: 'show',
      location: {
        country: '',
        distri: '',
      },
      myGarage: {
        running: -1,
        version: '',
        match: '=',
      },
      vpn: -1,
      displaysConnected: -1,
      internetSpeed: -1,
      note: -1,
      tag: '',
      tags: [],
      system: {
        os: 'all',
      },
      miserver: 'all',
    };
  },
  getSelected(filter: any): number {
    const emptyFilter = this.empty() as any;
    let selected = 0;
    for (let key in filter) {
      if (typeof emptyFilter[key] !== 'undefined' && typeof emptyFilter[key] !== 'object') {
        if (emptyFilter[key] != filter[key]) {
          selected++;
        }
      } else if (typeof filter[key] === 'object') {
        for (let subKey in filter[key]) {
          if (emptyFilter[key][subKey] != filter[key][subKey]) {
            selected++;
          }
        }
      }
    }

    return selected;
  },
  generate(options: any) {
    return { ...this.empty(), ...options };
  },
};

class Version {
  public version: number[];

  constructor(version: string) {
    this.version = version.split('.')
    .filter((e) => {
      return !isNaN(e as any);
    })
    .map((e) => {
        return parseInt(e, 10);
    });
  }

  isGreaterOrEqualThan(otherVersion: Version) {
    for (let i = 0; i < this.version.length; i++) {
      if (typeof otherVersion.version[i] === 'undefined') {
        return true;
      }

      if (otherVersion.version[i] < this.version[i]) {
        return true;
      }
      if (otherVersion.version[i] > this.version[i]) {
        return false;
      }
    }

    // Other version is the same in the main parts but is longer than our version
    if (otherVersion.version.length > this.version.length) {
      return false;
    }

    // They are the same
    return true;
  }

  isSmallerOrEqualThan(otherVersion: Version) {
    for (let i = 0; i < this.version.length; i++) {
      if (typeof otherVersion.version[i] === 'undefined') {
        return false;
      }

      if (otherVersion.version[i] < this.version[i]) {
        return false;
      }
      if (otherVersion.version[i] > this.version[i]) {
        return true;
      }
    }

    // Other version is the same in the main parts but is longer than our version
    if (otherVersion.version.length > this.version.length) {
      return true;
    }

    // They are the same
    return true;
  }
}
