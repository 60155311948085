<template>
  <div class="alert-viewer">
    <div class="alert-actions">
      <a class="clear" @click="$router.push('/alerts') && $root.$emit('closeAlerts')"
        >Go to alert overview</a
      >
    </div>
    <div class="alert-inner">
      <div class="empty" v-if="alerts.length === 0">No Alerts Found</div>
      <div v-else>
        <div v-for="alert in alerts" :key="alert._id">
          <Notification :alert="alert" @change="getAlerts()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils';
import BaseComponent from '../Base.vue';
import Notification from './AlertNotification.vue';

export default {
  name: 'AlertDropdown',
  extends: BaseComponent,
  data() {
    return {
      ajaxCompleted: false,
      alerts: [],
      interval: null,
    };
  },
  components: {
    Notification,
  },
  methods: {
    async getAlerts() {
      const response = await Utils.fetch('/api/v1/alerts/triggers', {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.alerts = response.alerts;
        this.$emit(
          'change',
          this.alerts.some((e) => !e.muted),
        );
      }
    },
  },
  mounted() {
    this.getAlerts();
    this.$root.$on('alertChange', () => {
      this.getAlerts();
    });
    this.interval = setInterval(this.getAlerts.bind(this), 15000);
  },
  beforeDestroy() {
    if (this.interval !== null) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.alert-viewer {
  position: absolute;
  width: 300px;
  background: var(--nav-color);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  left: 50%;
  top: 100%;
  margin-top: 10px;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.3);
  z-index: 1;

  &::after {
    background: var(--nav-color);
    border: 1px solid rgba(255, 255, 255, 0.2);
    content: '\00a0';
    display: block;
    height: 20px;
    left: 46.2%;
    position: absolute;
    top: -11px;
    z-index: -1;
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 20px;
    clip-path: polygon(0 0, 0 100%, 100% 0);
  }

  .alert-actions {
    font-size: 11px;
    text-align: right;
    margin-top: -7px;
    margin-bottom: 3px;
  }

  .alert-inner {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;

    .empty {
      padding: 10px;
    }
  }
}

.alert-actions .clear {
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
}
</style>
