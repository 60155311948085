<template>
  <card title="YoYo Actions" v-if="device.online">
    <div style="margin-top: 10px">
      It takes some time for feedback to reach your computer. Please watch your clicks.
    </div>
    <div class="properties">
      <ul>
        <li>
          <a class="link" @click="updateYoYo(true)">Update YoYo (stable)</a>
        </li>
        <li>
          <a class="link" @click="updateYoYo(false)">Update YoYo (beta)</a>
        </li>
      </ul>
    </div>
  </card>
</template>
<script>
import Card from '../Card/Card.vue';

export default {
  name: 'YoYoStats',
  props: ['device'],
  components: {
    Card,
  },
  methods: {
    updateYoYo(stable) {
      this.$emit('updateYoYo', stable);
    },
  },
};
</script>

<style scoped lang="text/scss"></style>
