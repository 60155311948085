/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import Theme from '../../models/enums/Theme';

interface ThemeState {
  currentTheme: Theme;
}

const state = () => ({
  currentTheme: Theme.Dark,
});
const actions = {};

const getters = {
  selectedTheme: (state: ThemeState) => state.currentTheme,
};

const mutations = {
  changeTheme(state: ThemeState, theme: Theme) {
    state.currentTheme = theme;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
