<template>
  <div v-if="tableData && tableData.length > 0" class="table">
    <h2 v-if="title">{{ title }}</h2>
    <div v-if="enableColumnFilter" class="columns-filter">
      <div class="column-filter-toggle" @click="columnFilterOpen = !columnFilterOpen">
        <span v-if="!columnFilterOpen"
          ><i class="uil uil-columns" /><span>Manage columns</span></span
        >
        <span v-else> <i class="uil uil-columns" /><span>Managing columns</span> </span>
      </div>
      <vue-tags-input
        class="tags-input"
        placeholder="Select columns"
        v-if="columnFilterOpen && filteredColumns.length > 0"
        v-model="tag"
        :tags="filteredTagColumns"
        :autocomplete-items="hiddenColumns"
        :autocomplete-min-length="0"
        :add-only-from-autocomplete="true"
        @tags-changed="
          (newColumns) => {
            columnsChanged(newColumns);
          }
        "
      />
    </div>
    <table>
      <thead>
        <tr>
          <th v-if="showOnlineStatus" class="online-status">Status</th>
          <th v-for="column in columnsToDisplay" :key="column.title">
            {{ column.title }}
          </th>
          <th v-if="showTags">Tags</th>
          <th v-if="showStatus" class="status">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="dataEntry in tableData"
          :key="dataEntry.id"
          :class="{ clickable: rowsClickable }"
          @click="$emit('rowClick', dataEntry.id)"
        >
          <td v-if="showOnlineStatus" class="online-status-column">
            <span v-if="dataEntry.online !== false && dataEntry.vpn.active" class="blue bullet" />
            <span v-else-if="dataEntry.online !== false" class="green bullet" />
            <span v-else class="red bullet" />
          </td>
          <td v-for="column in columnsToDisplay" :key="column.key">
            <div class="break-value" v-if="column.breakValue">
              <p>{{ dataEntry[column.key] }}</p>
            </div>
            <i v-if="dataEntry[column.key] === true" class="uil uil-check-circle success" />
            <p v-else-if="dataEntry[column.key] !== false">
              {{ !column.breakValue ? dataEntry[column.key] : '' }}
            </p>
          </td>
          <td v-if="showTags">
            <TagProperty :value="dataEntry.tags" :hidespacing="true" />
          </td>
          <td v-if="showStatus" class="status-column">
            <i v-if="dataEntry.success === true" class="uil uil-check-circle success" />
            <i v-else-if="dataEntry.success === false" class="uil uil-times-circle error" />
            <i v-else-if="dataEntry._id !== undefined" class="uil uil-question-circle warning" />
          </td>
        </tr>
      </tbody>
    </table>
    &nbsp;
  </div>
</template>
<script>
import VueTagsInput from '@johmun/vue-tags-input';
import TagProperty from '../Property/TagProperty.vue';

export default {
  name: 'TableComponent',
  components: {
    VueTagsInput,
    TagProperty,
  },
  props: {
    title: String,
    enableColumnFilter: {
      type: Boolean,
      default: false,
    },
    enableRowSelect: {
      type: Boolean,
      default: false,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
    showOnlineStatus: {
      type: Boolean,
      default: false,
    },
    showTags: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: null,
    },
    tableData: {
      type: Array,
      default: null,
    },
    rowsClickable: {
      type: Boolean,
      default: null,
    },
    columnName: {
      type: String,
      default: 'columns',
    },
  },
  data() {
    return {
      tag: '',
      columnFilterOpen: false,
      filteredColumns: [],
    };
  },
  methods: {
    itemName() {
      return this.columnName;
    },
    storeColumns() {
      localStorage.setItem(this.itemName(), JSON.stringify(this.filteredColumns));
    },
    loadColumns() {
      const json = localStorage.getItem(this.itemName());
      if (json) {
        this.filteredColumns = JSON.parse(json);
      }
    },
    columnsChanged(newColumns) {
      this.filteredColumns = newColumns.map((t) => t.text);
      this.storeColumns();
    },
  },
  watch: {
    columns() {
      this.filteredColumns = this.columns.map((t) => ({ text: t }));
    },
  },
  computed: {
    filteredTagColumns() {
      return this.filteredColumns.map((t) => ({ text: t }));
    },
    columnsToDisplay() {
      if (this.enableColumnFilter) {
        return this.columns.filter((column) => this.filteredColumns.includes(column.title));
      }
      return this.columns;
    },
    hiddenColumns() {
      return this.columns
        .filter((column) => !this.filteredColumns.includes(column.title))
        .map((column) => ({ text: column.title }));
    },
  },
  mounted() {
    this.loadColumns();
    if (!this.filteredColumns.length) {
      if (this.columns && this.columns.length > 0) {
        this.filteredColumns = this.columns.map((column) => column.title);
        this.storeColumns();
      }
    }
  },
};
</script>
<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
.break-value {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.column-filter-toggle {
  cursor: pointer;
}
.vue-tags-input:not(.light) {
  background: rgba(255, 255, 255, 0.05);
  width: 100%;

  input {
    font-size: 12px;
  }
}

table {
  width: 100%;
  margin-top: 16px;
  box-sizing: border-box;
  border-collapse: collapse;
  border: 2px solid rgba(255, 255, 255, 0.02);
}
tr {
  height: 30px;

  &:nth-child(even) {
    background: rgba(255, 255, 255, 0.02);
  }
}
th,
td {
  white-space: pre-line;
  padding: 4px 10px;
  box-sizing: border-box;
  max-width: 200px;
}
thead tr th {
  text-align: left;
  background: var(--table-header-color);
}
.status,
.online-status {
  width: 40px;
}

.clickable {
  cursor: pointer;
  &:hover {
    background: var(--table-row-hover-color);
  }
}

.online-status-column,
.status-column {
  font-size: 18px;
  align-items: center;
  width: 40px;
  text-align: center;
}

.success {
  color: #4dbd74;
}

.error {
  color: #f86c6b;
}

.warning {
  color: #f8b36b;
}

.bullet {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 100%;

  &.blue {
    background: rgb(88, 195, 220);
  }

  &.green {
    background: #4dbd74;
  }

  &.red {
    background: #f86c6b;
  }
}
</style>
