export enum ButtonType {
  'Delete' = 'DELETE',
  'Create' = 'CREATE',
  'Add' = 'ADD',
  'Cancel' = 'CANCEL',
  'Warning' = 'WARNING',
  'Themed' = 'THEMED',
  'ThemedDelete' = 'THEMED_DELETE',
}

export enum ButtonIconType {
  'Sync' = 'SYNC',
  'Trash' = 'TRASH',
  'Check' = 'CHECK',
  'Plus' = 'PLUS',
  'Edit' = 'EDIT',
  'Cancel' = 'CANCEL',
  'Download' = 'DOWNLOAD',
  'Save' = 'SAVE',
  'Back' = 'BACK',
  'Forward' = 'FORWARD',
}
