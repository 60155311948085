<template>
  <card title="YoYo Stats">
    <TextProperty
      title="YoYo Version"
      v-if="device.stats !== undefined && device.stats.yoyoVersion !== undefined"
      :value="device.stats.yoyoVersion"
    />
    <TextProperty
      title="YoYo Version"
      v-if="device.stats !== undefined && device.stats.yoyo !== undefined"
      :value="device.stats.yoyo.yoyo.version"
    />
    <TextProperty
      title="YoYo is running"
      v-if="device.stats !== undefined && device.stats.yoyoRunning !== undefined"
      :value="device.stats.yoyoRunning ? 'Yes' : 'No'"
    />
    <TextProperty
      title="YoYo is running"
      v-if="device.stats !== undefined && device.stats.yoyo !== undefined"
      :value="device.stats.yoyo.yoyo.isRunning ? 'Yes' : 'No'"
    />
    <TextProperty
      title="Connected Reader(s)"
      v-if="device.stats !== undefined && device.stats.nfcReaders !== undefined"
      :value="device.stats.nfcReaders.toString()"
    />
    <TextProperty
      v-if="device.stats !== undefined && device.stats.yoyo !== undefined"
      title="Connected Reader(s)"
      :value="device.stats.yoyo.readers.toString()"
    />
  </card>
</template>

<script>
import TextProperty from '../Property/TextProperty.vue';
import Card from '../Card/Card.vue';

export default {
  name: 'YoYoStats',
  components: {
    Card,
    TextProperty,
  },
  props: ['device'],
};
</script>

<style scoped lang="text/scss"></style>
