import Vue from 'vue';

const BytesToHumanFunction = (num) => {
  let numberToReturn = 0;
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || Number.isNaN(num)) {
    throw new TypeError('Expected a number');
  }

  const neg = num < 0;
  const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    numberToReturn = -num;
  }

  if (num < 1) {
    return `${(neg ? '-' : '') + num} B`;
  }

  const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  numberToReturn = (num / 1000 ** exponent).toFixed(2) * 1;
  const unit = units[exponent];

  return `${(neg ? '-' : '') + numberToReturn} ${unit}`;
};

/**
 * Usage: in vue file : {{ bytes | bytestohuman }}
 */
Vue.filter('bytestohuman', BytesToHumanFunction);

/**
 * Usage:  {{ $bytestohuman(bytes) }}
 * Usage:  const h = this.$bytestohuman(12345)
 */
Object.defineProperties(Vue.prototype, {
  $bytestohuman: {
    get() {
      return BytesToHumanFunction;
    },
  },
});
