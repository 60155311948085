<template>
  <div />
</template>
<script>
import Vue from 'vue';

export default {
  name: 'Logout',
  mounted() {
    localStorage.removeItem('jwt');
    this.$root.$emit('logout');
    Vue.nextTick(() => {
      this.$router.push({
        name: 'login',
        query: this.$route.query,
      });
    });
  },
};
</script>
<style scoped></style>
