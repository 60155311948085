<template>
  <div class="pane-wrapper">
    <splitpanes
      vertical
      class="wherever-theme"
      @resized="saveLayout($event)"
      :dbl-click-splitter="false"
    >
      <pane :size="leftPaneSize">
        <slot name="left" />
      </pane>
      <pane :size="100 - leftPaneSize">
        <slot name="right" />
      </pane>
    </splitpanes>
  </div>
</template>

<script>
import { Pane, Splitpanes } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

export default {
  name: 'Panes',
  components: {
    Splitpanes,
    Pane,
  },
  data() {
    return {
      ready: false,
      leftPaneSize: 45,
    };
  },
  props: {
    leftPane: Number,
  },
  computed: {},
  methods: {
    itemName() {
      if (this.route?.name) {
        return `vertical-${this.$route.name}`;
      }
      return '';
    },
    saveLayout(evt) {
      localStorage.setItem(this.itemName(), parseInt(evt[0].size, 10).toString());
    },
  },
  mounted() {
    this.ready = true;

    if (this.leftPane) {
      this.leftPaneSize = this.leftPane;
    }

    const leftPaneSize = localStorage.getItem(this.itemName());
    if (leftPaneSize) {
      this.leftPaneSize = leftPaneSize;
    }
  },
};
</script>

<style scoped>
.pane-wrapper {
  height: 100%;
}
</style>

<style lang="scss">
.splitpanes.wherever-theme {
  .splitpanes__splitter {
    background-color: var(--panes-splitter-color);
    box-sizing: border-box;
    position: relative;
    flex-shrink: 0;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: rgba(255, 255, 255, 0.25);
      transition: background-color 0.3s;
    }
    &:hover:before,
    &:hover:after {
      background-color: rgba(255, 255, 255, 0.75);
    }
    &:first-child {
      cursor: auto;
    }
  }
}
.wherever-theme {
  &.splitpanes .splitpanes .splitpanes__splitter {
    z-index: 1;
  }
  &.splitpanes--vertical > .splitpanes__splitter,
  .splitpanes--vertical > .splitpanes__splitter {
    width: 7px;
    margin-left: -1px;
    &:before,
    &:after {
      transform: translateY(-50%);
      width: 1px;
      height: 30px;
    }
    &:before {
      margin-left: -2px;
    }
    &:after {
      margin-left: 1px;
    }
  }
  &.splitpanes--horizontal > .splitpanes__splitter,
  .splitpanes--horizontal > .splitpanes__splitter {
    height: 7px;
    border-top: 1px solid var(--panes-splitter-color);
    margin-top: -1px;
    &:before,
    &:after {
      transform: translateX(-50%);
      width: 30px;
      height: 1px;
    }
    &:before {
      margin-top: -2px;
    }
    &:after {
      margin-top: 1px;
    }
  }
  .splitpanes__pane {
    margin: 0 15px;
  }
}
</style>
