<template>
  <div v-if="userCan('view', 'ssl')">
    <card title="SSL Certificate Deployment">
      <div v-if="online">
        <base-button
        :isSmall="true"
        type="THEMED"
        v-on:click="deploySSLCertificate"
        title="Deploy certificates">
          <i class="uil uil-cloud-upload"/>
        </base-button>

        <base-button
        :isSmall="true"
        type="THEMED"
        v-on:click="sslCertificateStatus"
        title="Get certificate status">
          <i class="uil uil-cloud-download"/>
        </base-button>
      </div>
      <div>
        <table class="cert-table">
          <tr>
            <th>Certificate</th>
            <th>Status</th>
            <th>Validity</th>
          </tr>
          <tr v-for="deployment in deployments" :key="deployment._id">
            <td>
              <a :href="`/certificate/${deployment.id}/edit`">{{ deployment.name }}</a>
            </td>
            <td>
              <span v-if="!deployment.installedSerial">
                <i class="uil uil-question-circle orange" />
              </span>
              <span v-else-if="deployment.installedSerial !== deployment.latestSerial">
                <i class="uil uil-times-circle red" />
              </span>
              <span v-else>
                <i class="uil uil-check-circle green" />
              </span>
            </td>
            <td>
              <span
              v-if="!deployment.installedSerial
              || deployment.installedSerial !== deployment.latestSerial">
                -
              </span>
              <span v-else>
                {{ dateToDays(deployment.validUntil) }} day(s)
              </span>
            </td>
          </tr>
        </table>
      </div>
    </card>
  </div>
</template>

<script>
import Card from '../Card/Card.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import Utils from '../../utils';

export default {
  name: 'SSLCertificateInfoCard',
  props: ['online', 'deviceUnique'],
  components: {
    Card,
    BaseButton,
  },
  data() {
    return {
      status: null,
      deployments: [],
    };
  },
  watch: {
    deviceUnique(newVal) {
      if (newVal) {
        this.getCertificateDeployments();
      }
    },
  },
  methods: {
    userCan(...args) {
      return Utils.userCan(...args);
    },
    sslCertificateStatus() {
      this.$emit('sslCertificateStatus');
    },
    deploySSLCertificate() {
      this.$emit('deploySSLCertificate');
    },
    handleSSLCertificateResponse(message) {
      if (message.device !== this.deviceUnique) {
        return;
      }

      if (message.subcommand === 'start') {
        if (message.success) {
          this.$noty.success('Started the SSL certificate deployment');
        } else {
          this.$noty.error(`Failed to start the SSL certificate deployment: ${message.message}`);
        }
      }

      if (message.subcommand === 'completed') {
        if (message.success) {
          this.$noty.success('Completed the SSL certificate deployment');
          this.getCertificateDeployments();
        } else {
          this.$noty.error(`Failed to complete the SSL certificate deployment: ${message.message}`);
        }
      }

      if (message.subcommand === 'status') {
        if (message.success) {
          this.$noty.success('Successfully updated the certificate status for this device.');
          this.getCertificateDeployments();
        }
      }
    },
    async getCertificateDeployments() {
      try {
        const response = await this.$utils.http().get(`/api/v1/certificates/device/${this.deviceUnique}`);
        this.deployments = response.data;
      } catch (err) {
        this.$noty.error(`Could not get certificate deployments for this device: ${err.response.data.message}`);
      }
    },
    dateToDays(dateString) {
      const now = Date.now();
      const then = new Date(dateString).getTime();

      const deltaMiliSeconds = then - now;
      return parseInt(deltaMiliSeconds / (1000 * 60 * 60 * 24), 10);
    },
  },
  mounted() {
    this.getCertificateDeployments();
    this.$root.$on('sslCertificate', this.handleSSLCertificateResponse);
  },
  beforeDestroy() {
    this.$root.$off('sslCertificate', this.handleSSLCertificateResponse);
  },
};
</script>

<style scoped lang="scss">
.status-message {
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 6px;
  margin-bottom: 4px;
}
.cert-table {
  margin-top: 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0,0,0,0.1);
  background: rgba(0,0,0,0.1);

  tr th {
    text-align: left;;
  }

  tr td, tr th {
    padding: 4px 5px;
  }

  tr:not(:last-child) td, tr:not(:last-child) th {
      border-bottom: 1px solid rgba(0,0,0,0.1);
  }
}
</style>
