<template>
  <div class="events-table">
    <table>
      <thead>
        <tr>
          <th>Direction</th>
          <th>Tag</th>
          <th>Trigger</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="log in events.logs" :key="log.id">
          <td>
            <b v-if="log.placeDown" class="uil uil-arrow-down green" />
            <b v-else class="uil uil-arrow-up red" />
          </td>
          <td>
            {{ log.tag }}
          </td>
          <td>
            {{ log.trigger }}
          </td>
          <td>
            {{ log.date }}
          </td>
        </tr>
      </tbody>
    </table>
    <grid>
      <grid-col size="2">
        <a v-if="page > 1" @click="page--" class="clickable" title="Previous page"
          ><i class="uil uil-angle-double-left" /> Prev</a
        >
      </grid-col>
      <grid-col size="8">
        <p class="center" v-if="events && events.pages && events.shown">
          Page {{ page }} of {{ events.pages }} [ events {{ events.shown[0] }} -
          {{ events.shown[1] }} ]
        </p>
      </grid-col>
      <grid-col size="2">
        <a v-if="page < events.pages" @click="page++" class="clickable" title="Next page"
          >Next <i class="uil uil-angle-double-right"
        /></a>
      </grid-col>
    </grid>
  </div>
</template>

<script>
import Utils from '@/utils';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';

export default {
  props: ['mac', 'host'],
  components: {
    Grid,
    GridCol,
  },
  name: 'Events',
  data() {
    return {
      events: {
        logs: [],
      },
      amount: 14,
      page: 1,
    };
  },
  watch: {
    mac(mac) {
      this.getDeviceEvents(this.host, mac);
    },
    host(host) {
      this.getDeviceEvents(host, this.mac);
    },
    page() {
      this.$nextTick(() => {
        this.getDeviceEvents(this.host, this.mac);
      });
    },
  },
  methods: {
    async getDeviceEvents(host, mac) {
      if (host && mac) {
        const res = await Utils.fetchRaw(
          `${host}/last/${mac}/${this.amount}/${this.page}`,
          {},
          this,
        );
        this.events = res;
      }
    },
  },
  async mounted() {
    this.getDeviceEvents(this.host, this.mac);
  },
};
</script>

<style scoped lang="scss">
.events-table {
  padding-top: 16px;
}

.events-table table {
  width: 100%;
  border-collapse: collapse;

  .loader {
    text-align: center;
    padding: 20px 0;

    img {
      height: 30px;
    }
  }

  tr {
    height: 30px;

    &.selected {
      background: var(--table-row-hover-color);
    }

    &:hover {
      background: var(--table-row-hover-color);
    }
  }

  th,
  td {
    padding: 0 10px;
  }

  thead tr th {
    text-align: left;
    background: var(--table-header-color);
  }

  th {
    font-weight: normal;
  }
}
</style>
