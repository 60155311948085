<template>
  <div v-if="enabled" class="loading">
    <div class="loader" :style="{ width: size + 'px' }">
      <svg
        class="lds-spinner"
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="rotate(0 50 50)">
          <rect x="47" y="21" rx="9.4" ry="4.2" width="6" height="18" fill="#99a5aa">
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1s"
              begin="-1.0083333333333335s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(30 50 50)">
          <rect x="47" y="21" rx="9.4" ry="4.2" width="6" height="18" fill="#99a5aa">
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1s"
              begin="-0.9166666666666666s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(60 50 50)">
          <rect x="47" y="21" rx="9.4" ry="4.2" width="6" height="18" fill="#99a5aa">
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1s"
              begin="-0.8250000000000001s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(90 50 50)">
          <rect x="47" y="21" rx="9.4" ry="4.2" width="6" height="18" fill="#99a5aa">
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1s"
              begin="-0.7333333333333334s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(120 50 50)">
          <rect x="47" y="21" rx="9.4" ry="4.2" width="6" height="18" fill="#99a5aa">
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1s"
              begin="-0.6416666666666667s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(150 50 50)">
          <rect x="47" y="21" rx="9.4" ry="4.2" width="6" height="18" fill="#99a5aa">
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1s"
              begin="-0.55s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(180 50 50)">
          <rect x="47" y="21" rx="9.4" ry="4.2" width="6" height="18" fill="#99a5aa">
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1s"
              begin="-0.4583333333333333s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(210 50 50)">
          <rect x="47" y="21" rx="9.4" ry="4.2" width="6" height="18" fill="#99a5aa">
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1s"
              begin="-0.3666666666666667s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(240 50 50)">
          <rect x="47" y="21" rx="9.4" ry="4.2" width="6" height="18" fill="#99a5aa">
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1s"
              begin="-0.275s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(270 50 50)">
          <rect x="47" y="21" rx="9.4" ry="4.2" width="6" height="18" fill="#99a5aa">
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1s"
              begin="-0.18333333333333335s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(300 50 50)">
          <rect x="47" y="21" rx="9.4" ry="4.2" width="6" height="18" fill="#99a5aa">
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1s"
              begin="-0.09166666666666667s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(330 50 50)">
          <rect x="47" y="21" rx="9.4" ry="4.2" width="6" height="18" fill="#99a5aa">
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1s"
              begin="0s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 36,
    },
    computed: {
      sizeStyle() {
        return { width: `${this.size} px` };
      },
    },
  },
};
</script>

<style lang="scss">
.loader {
  text-align: center;
  padding: 20px 0;
}

svg {
  max-width: 100%;
}
</style>
