<template>
  <div class="container-fluid">
    <grid>
      <grid-col size="12">
        <h1>Dashboard</h1>
      </grid-col>
    </grid>
    <grid>
      <grid-col size="6">
        <card>
          <h2>Connection status</h2>
          <grid>
            <grid-col size="4">
              <div class="sum bg-green">
                <router-link :to="genFilter({ online: '1' })">
                  <i class="uil uil-check-circle right" />
                </router-link>
                <p>Online</p>
                <span>{{ sums.online }}</span
                ><span class="fromtotal"> / {{ sums.approved }}</span>
              </div>
            </grid-col>
            <grid-col size="4">
              <div class="sum bg-red">
                <router-link :to="genFilter({ online: '0' })">
                  <i class="uil uil-times-circle right" />
                </router-link>
                <p>Offline</p>
                <span>{{ sums.offline }}</span
                ><span class="fromtotal"> / {{ sums.approved }}</span>
              </div>
            </grid-col>
            <grid-col size="4">
              <div :class="'sum ' + (sums.alerted ? 'bg-darkred' : 'bg-darkgreen')">
                <router-link :to="genFilter({ alert: 1 })">
                  <i
                    :class="
                      'uil ' +
                      (sums.alerted ? 'uil-shield-exclamation' : 'uil-shield-check') +
                      ' right'
                    "
                  />
                </router-link>
                <p>Alerts</p>
                <span>{{ sums.alerted }}</span
                ><span class="fromtotal"> / {{ sums.approved }}</span>
              </div>
            </grid-col>
          </grid>
        </card>
        <card>
          <h2>Approval status</h2>
          <grid>
            <grid-col size="4">
              <div class="sum bg-green">
                <router-link :to="'/devices/approved'">
                  <i class="uil uil-shield-check right" />
                </router-link>
                <p>Approved</p>
                <span>{{ sums.approved }}</span
                ><span class="fromtotal"> / {{ sums.total }}</span>
              </div>
            </grid-col>

            <grid-col size="4">
              <div class="sum bg-orange">
                <router-link :to="'/devices/unapproved'">
                  <i class="uil uil-shield-exclamation right" />
                </router-link>
                <p>Unapproved</p>
                <span>{{ sums.unapproved }}</span>
                <span class="fromtotal"> / {{ sums.total }}</span>
              </div>
            </grid-col>
          </grid>
        </card>
        <card>
          <h2>Average statistics of all devices</h2>
          <grid v-if="userCan('view', 'device')">
            <grid-col size="3" class="no-overflow">
              <gauge :value="avgs.cpu" title="CPU" />
            </grid-col>
            <grid-col size="3">
              <gauge :value="avgs.temp" title="Temperature" :no-percent="true" />
            </grid-col>
            <grid-col size="3">
              <gauge :value="avgs.mem" title="Memory" />
            </grid-col>
            <grid-col size="3">
              <gauge :value="avgs.disk" title="Disk" />
            </grid-col>
          </grid>
          <grid v-if="userCan('view', 'command')">
            <grid-col size="12">
              <h3>Script command executed</h3>
              <CommandsTable />
            </grid-col>
          </grid>
        </card>
      </grid-col>
      <grid-col size="6">
        <card>
          <h2>World view</h2>
          <gmap-map
            :center="{ lat: 49, lng: 7.177406 }"
            :zoom="3"
            :options="mapOptions"
            map-type-id="terrain"
            class="map"
          >
            <gmap-info-window
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen = false"
            >
              <div v-html="infoContent" @click="linkToMarker" class="marker-link" />
            </gmap-info-window>
            <gmap-marker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :icon="markerOptions"
              @click="toggleInfoWindow(m, index)"
            />
          </gmap-map>
        </card>
      </grid-col>
    </grid>
  </div>
</template>

<script>
// Disabled this rules as we need to find a new way to load the marker.
// Conditional requires are not a good practice, but they do the job for now.
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable import/no-unresolved */
import Utils from '../utils';
import Filter from '../filter';
import BaseComponent from '../components/Base.vue';
import CommandsTable from '../components/Table/Commands.vue';
import Gauge from '../components/Dashboard/Gauge.vue';
import Card from '../components/Card/Card.vue';
import Grid from '../components/Grid/Grid.vue';
import GridCol from '../components/Grid/GridCol.vue';

export default {
  name: 'Dashboard',
  extends: BaseComponent,
  components: {
    Grid,
    GridCol,
    Card,
    Gauge,
    CommandsTable,
  },
  data() {
    return {
      interval: null,
      flavor: 'yme',

      // Array will be automatically processed with visualization.arrayToDataTable function
      chartData: [
        ['Label', 'Value'],
        ['CPU', 0],
        ['Temp', 0],
        ['Memory', 0],
        ['Disk', 0],
      ],
      chartOptions: {
        width: 600,
        height: 150,
        yellowFrom: 50,
        yellowTo: 80,
        redFrom: 80,
        redTo: 100,
      },
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
      sums: {
        total: 0,
        online: 0,
        offline: 0,
        approved: 0,
        unapproved: 0,
        unknown: 0,
        alerted: 0,
      },
      avgs: {
        cpu: 0,
        temp: 0,
        mem: 0,
        disk: 0,
      },
      infoContent: '',
      currentMarker: null,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: -15,
          height: -58,
        },
      },
      markers: [],
      markerOptions: {
        url: require('@/assets/yme-marker.png'),
      },
    };
  },
  methods: {
    userCan(...args) {
      return Utils.userCan(...args);
    },
    async getSums() {
      const response = await this.$utils.http().get('/api/v1/stats/sums');
      this.sums = response.data;
    },
    async getAvgs() {
      const response = await this.$utils.http().get('/api/v1/stats/averages');

      this.chartData = [
        ['Label', 'Value'],
        ['CPU', Math.round(response.data.cpu)],
        ['Temp', Math.round(response.data.temp)],
        ['Memory', Math.round(response.data.mem)],
        ['Disk', Math.round(response.data.disk)],
      ];
      this.avgs = response.data;
    },
    async loadData() {
      this.getAvgs();
      this.getSums();

      const response = await this.$utils.http().get('/api/v1/stats/locations');
      this.markers = response.data.map((location) => ({
        _id: location.id,
        position: location.position,
        name: location.name,
        url: `/devices/approved/${location.id}`,
      }));
    },
    genFilter(options) {
      return `/devices/approved#${Filter.serializeFilter(Filter.generate(options))}`;
    },
    linkToMarker() {
      this.$router.push(this.currentMarker.url);
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoContent = marker.name;
      this.currentMarker = marker;

      // check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        // if different marker set infowindow to open and reset current marker index
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    async loadFlavor() {
      const response = await this.$utils.http().get('/api/v1/flavor');
      this.flavor = response.data;
      const url = `/${this.flavor}-marker.png`;
      if (url.indexOf('/-marker.png') === -1) {
        this.markerOptions.url = require(`@/assets/${this.flavor}-marker.png`);
      }
    },
  },
  async mounted() {
    this.loadFlavor().then(() => {
      this.loadData();
      this.interval = setInterval(() => {
        this.getAvgs();
        this.getSums();
      }, 60000);
    });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
<style scoped>
div.sum {
  color: #ffffff;
  padding: 10px;
  border-radius: 4px;
  position: relative;
}

.no-overflow {
  overflow: hidden;
}

div.sum > span {
  font-size: x-large;
  font-weight: bold;
}

div.sum span.fromtotal {
  opacity: 0.5;
  font-size: large;
  font-style: italic;
}

div.sum a,
div.sum a:visited {
  color: var(--secondary-font-color);
}

div.sum i {
  font-size: 70px;
  opacity: 0.4;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dashboard-title {
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px;
}

.margin-bottom {
  margin-bottom: 30px;
}

.map {
  width: 100%;
  height: 700px;
}

.marker-link {
  font-weight: bold;
  cursor: pointer;
}
</style>
