<template>
  <div :class="colClass">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GridCol',
  props: ['size'],
  computed: {
    colClass() {
      return this.size ? `col-${this.size}` : 'col-12';
    },
  },
};
</script>
<style lang="scss" scoped>
.col-1 {
  grid-column: span 1;
  overflow: hidden;
}
.col-2 {
  grid-column: span 2;
  overflow: hidden;
}
.col-3 {
  grid-column: span 3;
  overflow: hidden;
}
.col-4 {
  grid-column: span 4;
  overflow: hidden;
}
.col-5 {
  grid-column: span 5;
  overflow: hidden;
}
.col-6 {
  grid-column: span 6;
  overflow: hidden;
}
.col-7 {
  grid-column: span 7;
  overflow: hidden;
}
.col-8 {
  grid-column: span 8;
  overflow: hidden;
}
.col-9 {
  grid-column: span 9;
  overflow: hidden;
}
.col-10 {
  grid-column: span 10;
  overflow: hidden;
}
.col-11 {
  grid-column: span 11;
  overflow: hidden;
}
.col-12 {
  grid-column: span 12;
  overflow: hidden;
}
</style>
