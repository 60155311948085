<!-- eslint-disable -->
<template>
  <div class="filter-wrapper">
    <card>
      <h4>
        Filter
        <span v-if="value.matched" class="right">Matched: {{ matched }}</span>
      </h4>
      <div class="properties">
        <div class="property two-to-one">
          <div class="left" style="flex: 2 0 0">
            <input
              type="text"
              name="filter-input"
              class="input-text filter-input"
              placeholder="Name, Internal IP, External IP, MAC, UUID"
              v-model="value.search"
            />
          </div>
          <div class="right">
            <span style="display: block; padding: 0 5px">
              <vue-tags-input
                class="tags-input"
                v-model="value.tag"
                :tags="value.tags"
                :max-tags="4"
                placeholder="Search by tags"
                :autocomplete-min-length="0"
                :autocomplete-items="filteredTags"
                :add-only-from-autocomplete="true"
                @tags-changed="(newTags) => (value.tags = newTags)"
              />
            </span>
          </div>
        </div>
        <div class="property">
          <div class="button-row">
            <base-button @click="showFilterModal = true" type="THEMED" title="More filters" />
            <div>
              <p>{{ selectedFilters }} filters selected.</p>
              <a class="link" @click="clearFilters()">clear filters</a>
            </div>
            <div class="clear-filter">
              <a class="link" @click="$emit('export')"> Export filtered to CSV </a>
            </div>
          </div>
        </div>
      </div>
    </card>
    <Modal v-show="showFilterModal" @cancel="showFilterModal = false">
      <h2>Filters</h2>
      <grid>
        <grid-col size="3">
          <Property title="Online status">
            <select name="status-online" class="input-select input-filters" v-model="value.online">
              <optgroup label="No preference">
                <option value="-1">-</option>
              </optgroup>
              <optgroup label="Online">
                <option value="1">Online</option>
              </optgroup>
              <optgroup label="Offline">
                <option value="0">Offline</option>
                <option :value="2 * 3600">Offline since 2 hours</option>
                <option :value="3 * 3600">Offline since 3 hours</option>
                <option :value="86400">Offline since 1 day</option>
              </optgroup>
            </select>
          </Property>
          <Property title="Never been online">
            <label>
              <input
                type="radio"
                name="showUnconfigured"
                value="show"
                v-model="value.showUnconfigured"
              />
              Show
            </label>
            &nbsp;
            <label>
              <input
                type="radio"
                name="showUnconfigured"
                value="hide"
                v-model="value.showUnconfigured"
              />
              Hide
            </label>
          </Property>
          <hr />
          <Property title="VPN status">
            <select name="status-online" class="input-select input-filters" v-model="value.vpn">
              <option value="-1">-</option>
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </select>
          </Property>
          <Property title="Display connected">
            <select
              name="status-online"
              class="input-select input-filters"
              v-model="value.displaysConnected"
            >
              <option value="-1">-</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </Property>
        </grid-col>
        <grid-col size="3">
          <Property title="Operating system">
            <select
              name="operating-system"
              class="input-select input-filters"
              v-model="value.system.os"
              @change="shouldSelectMiserver"
            >
              <option value="all">All</option>
              <option value="windows">Windows</option>
              <option value="magicinfo" v-if="miservers">Magicinfo</option>
              <option value="linux">Linux</option>
            </select>
          </Property>
          <Property title="MagicINFO server" v-if="miservers && selectMiserver">
            <select
              name="magicinfo-server"
              class="input-select input-filters"
              v-model="value.miserver"
            >
              <option value="all">All</option>
              <option v-for="srv in miservers" :value="srv._id" :key="srv._id">
                {{ srv.name }}
              </option>
            </select>
          </Property>
          <Property title="Has alert">
            <select name="has-alert" class="input-select input-filters" v-model="value.alert">
              <option value="-1">-</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </Property>
          <hr />
          <Property title="With Notes">
            <select name="status-facet" class="input-select input-filters" v-model="value.note">
              <option value="-1">-</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </Property>
          <Property title="Internet speed">
            <select
              name="status-online"
              class="input-select input-filters"
              v-model="value.internetSpeed"
            >
              <option value="-1">-</option>
              <option value="1">Lower than 1 Mbit/s</option>
              <option value="3">Lower than 3 Mbit/s</option>
              <option value="5">Lower than 5 Mbit/s</option>
              <option value="10">Lower than 10 Mbit/s</option>
              <option value="15">Lower than 15 Mbit/s</option>
              <option value="30">Lower than 30 Mbit/s</option>
              <option value="50">Lower than 50 Mbit/s</option>
              <option value="100">Lower than 100 Mbit/s</option>
            </select>
          </Property>
        </grid-col>
        <grid-col size="4">
          <Property title="Country">
            <select
              name="status-facet"
              class="input-select input-filters"
              v-model="value.location.country"
            >
              <option value="">-</option>
              <option
                v-for="country in filterHelper.countries"
                :value="country.value"
                :key="country.value"
              >
                {{ country.label }}
              </option>
            </select>
          </Property>
          <Property title="Distributor">
            <select
              name="status-facet"
              class="input-select input-filters"
              v-model="value.location.distri"
            >
              <option value="">-</option>
              <option
                v-for="distry in filterHelper.distries"
                :value="distry.value"
                :key="distry.value"
              >
                {{ distry.label }}
              </option>
            </select>
          </Property>
          <hr />
          <Property title="My Garage App Status">
            <select
              name="status-facet"
              class="input-select input-filters"
              v-model="value.myGarage.running"
            >
              <option value="-1">-</option>
              <option value="1">Running</option>
              <option value="0">Not running</option>
            </select>
          </Property>
          <Property title="My Garage App Version">
            <select
              name="status-facet"
              class="input-select input-filters"
              v-model="value.myGarage.version"
            >
              <optgroup label="No preference">
                <option value="">-</option>
              </optgroup>
              <optgroup label="Version">
                <option
                  v-for="version in filterHelper.facets.mgaVersion"
                  :value="version"
                  :key="version"
                >
                  {{ version }}
                </option>
              </optgroup>
            </select>
          </Property>
          <Property title="My Garage App Version">
            <label>
              <input type="radio" value="=" name="myGarageExact" v-model="value.myGarage.match" />
              Exact
            </label>
            &nbsp;
            <label>
              <input type="radio" value=">=" name="myGarageExact" v-model="value.myGarage.match" />
              Greater
            </label>
            &nbsp;
            <label>
              <input type="radio" value="<=" name="myGarageExact" v-model="value.myGarage.match" />
              Lower
            </label>
          </Property>
        </grid-col>
      </grid>
      <hr />
      <div class="clearfix">
        <base-button
          type="THEMED"
          class="left"
          v-on:click="showFilterModal = false"
          title="Close"
        />
        <base-button
          class="right"
          type="THEMED"
          iconType="CANCEL"
          v-on:click="clearFilters()"
          title="Clear filters"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
// TODO: Changing props is not a good thing in Vue.
// This component should be altered so that it does not have this behaviour anymore.
/* eslint-disable vue/no-mutating-props */
import Utils from '@/utils';
import FilterModule from '@/filter';
import VueTagsInput from '@johmun/vue-tags-input';
import Modal from '@/components/Util/Modal.vue';
import Property from '@/components/Util/Property.vue';
import Card from '../../Card/Card.vue';
import Grid from '../../Grid/Grid.vue';
import GridCol from '../../Grid/GridCol.vue';
import BaseButton from '../../BaseButton/BaseButton.vue';

export default {
  name: 'FilterWindow',
  components: {
    Card,
    VueTagsInput,
    Modal,
    Property,
    Grid,
    GridCol,
    BaseButton,
  },
  props: ['value', 'matched'],
  data() {
    return {
      filterHelper: {
        facets: {},
        distries: [],
        countries: [],
      },
      autocompleteItems: [],
      showFilterModal: false,
      miservers: null,
      selectMiserver: false,
    };
  },
  computed: {
    filteredTags() {
      return this.autocompleteItems.filter(
        (i) => i.text.toLowerCase().indexOf(this.value.tag.toLowerCase()) !== -1,
      );
    },
    selectedFilters() {
      return FilterModule.getSelected(this.value);
    },
  },
  methods: {
    shouldSelectMiserver(event) {
      if (event.target.value === 'magicinfo') {
        this.selectMiserver = true;
      } else {
        this.value.miserver = 'all';
        this.$nextTick(() => {
          this.selectMiserver = false;
        });
      }
    },
    async getTags() {
      const response = await this.$utils.http().get('/api/v1/tags');
      this.autocompleteItems = response.data.sort().map((e) => ({ text: e }));
    },
    getProperties() {
      this.$utils
        .http()
        .get('/api/v1/devices/facets')
        .then((response) => {
          this.filterHelper.facets = response.data;
        });

      const user = Utils.getUser();
      this.$utils
        .http()
        .get(`/api/v1/organizations/${user.organization}/properties/yme`)
        .then((response) => {
          this.filterHelper.countries = response.data.countries;
          this.filterHelper.distries = response.data.distries;
        });
    },
    async getMiservers() {
      try {
        const response = await this.$utils.http().get('/api/v1/tags');
        this.miservers = response.data;
      } catch (e) {
        this.miservers = null;
      }
    },
    clearFilters() {
      // this.value is a copy of a reference to the same object as mentioned in
      // parent components. This means that simply reassigning this.value would
      // destroy this reference. Thats why we 'use/follow' the reference to
      // change the referenced object, by only changing its properties.
      const emtpyFilter = FilterModule.empty();

      // eslint-disable-next-line no-restricted-syntax
      for (const key in emtpyFilter) {
        if (Object.prototype.hasOwnProperty.call(emtpyFilter, key)) {
          this.value[key] = emtpyFilter[key];
        }
      }
    },
  },
  mounted() {
    this.getTags();
    this.getProperties();
    this.getMiservers();
    if (this.value.system.os === 'magicinfo') {
      this.selectMiserver = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.filter-wrapper {
  display: flex;
  width: 100%;
}

.button-row {
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }
  .clear-filter {
    margin-left: auto;
    display: inline-block;
    font-size: 12px;
    line-height: 1.2;
    a {
      color: var(--secondary-font-color);
      text-decoration: underline;
    }
  }
}
.filter-button {
  background: #2c3237;
  padding: 6px 22px;
  border-radius: 4px;
  border: 1px solid #23282c;
  cursor: pointer;

  &:hover {
    background: rgba(44, 50, 55, 0.7);
  }
}

.input-filters {
  height: auto;
  padding: 4px 4px;
  font-size: 12px;

  optgroup {
    color: black;
  }
}

.export {
  line-height: 2.4;
}

.filter-input {
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding: 9px 8px;
}

.properties > .property.two-to-one {
  display: flex;
  flex-direction: row;
  .left {
    flex: 2 0 0;
  }
  .right {
    flex: 1 0 0;
  }
}

.input-select {
  width: 160px;
}

.modal-title {
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 0;
  margin: 0;
}
</style>
