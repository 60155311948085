<template>
  <div v-if="resticData">
    <card title="Restic Stats">
      <div v-if="resticData.initialized">
        <div v-if="resticData.lastRun">
          <Property title="Last run" v-if="resticData.lastRun.start">
            {{
              $date(resticData.lastRun.start).format('LLL') +
              ` (${$date(resticData.lastRun.start).fromNow()})`
            }}
          </Property>
          <Property title="Last run" v-else>never</Property>
          <Property
            title="Next run (approx)"
            v-if="resticData.schedule && resticData.schedule.enabled"
          >
            {{ nextRun(resticData.lastRun) }}
          </Property>
          <Property title="Next run" v-else>-</Property>

          <Property title="Snapshots" v-if="resticData.snapshots">
            <router-link :to="{ name: 'resticSnaps', params: { restic: restic._id } }">
              Total {{ resticData.snapshots.length }} snapshots
            </router-link>
          </Property>
          <Property v-if="resticData.lastRun && resticData.lastRun.stats" title="Latest snapshot">
            Total size
            {{ resticData.lastRun.stats.total_bytes_processed | bytestohuman }}
            bytes in {{ resticData.lastRun.stats.total_files_processed }} files
          </Property>
          <Property
            title="Last backup started"
            v-if="resticData.lastRun && resticData.lastRun.start"
          >
            {{
              $date(resticData.lastRun.start).format('LLL') +
              ' (' +
              $date(new Date(resticData.lastRun.start)).fromNow() +
              ')'
            }}
          </Property>
          <Property title="Last backup ended" v-if="resticData.lastRun && resticData.lastRun.end">
            {{
              $date(resticData.lastRun.end).format('LLL') +
              ' (' +
              $date(new Date(resticData.lastRun.end)).fromNow() +
              ')'
            }}
          </Property>
          <Property
            title="Last backup duration"
            v-if="resticData.lastRun && resticData.lastRun.end"
          >
            {{ calculateBackupDuration(resticData.lastRun) }}
          </Property>
        </div>
        <div v-else>Stats will become available after the first backup</div>
      </div>
      <div v-else>Restic has not run yet</div>
    </card>
    <card title="Restic Control">
      <div class="properties" v-if="online && deviceUnique">
        <div v-if="resticData.initialized">
          <div v-if="!status">
            <base-button :isSmall="true" type="THEMED" v-on:click="resticBackup" title="Backup now"
              ><i class="uil uil-cloud-upload"
            /></base-button>
          </div>
          <div v-else-if="status && status === 'backup:clicked'">
            <Property title="Backup status"> Trying to start... </Property>
          </div>
          <div v-else-if="status && status === 'backup:start'">
            <Property title="Backup status"> Running... </Property>
            <Property title="Running since">
              {{
                $date(new Date(resticData.run.start)).format('LLL') +
                ' (' +
                $date(new Date(resticData.run.start)).fromNow() +
                ')'
              }}
            </Property>
          </div>
        </div>
        <div v-else>
          <p>Restic has not been initialized</p>
        </div>
      </div>
      <div v-if="deviceUnique">
        <router-link :to="{ name: 'resticView', params: { restic: resticData._id } }"
          ><base-button type="THEMED" :isSmall="true" title="View properties" />
        </router-link>
      </div>
      <div v-else>
        <router-link :to="{ name: 'devices', params: { device: resticData.device._id } }"
          ><base-button type="THEMED" :isSmall="true" title="Control device" />
        </router-link>
      </div>
    </card>
  </div>
</template>

<script>
import Property from '../Util/Property.vue';
import Card from '../Card/Card.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'ResticStats',
  props: ['online', 'restic', 'deviceUnique'],
  components: {
    Property,
    Card,
    BaseButton,
  },
  data() {
    return {
      status: null,
      resticData: this.restic,
    };
  },
  watch: {
    restic() {
      this.$nextTick(this.initBackupStatus);
      this.resticData = this.restic;
    },
    deviceUnique() {
      this.$nextTick(this.initBackupStatus);
    },
  },
  methods: {
    initBackupStatus() {
      if (!this.restic) {
        this.status = null;
        return;
      }

      if (this.restic.run && this.restic.run.start && !this.restic.run.end) {
        this.status = 'backup:start';
      } else {
        this.status = null;
      }
    },
    resticBackup() {
      this.status = 'backup:clicked';
      this.$emit('resticBackup');
    },
    handleResticResponse(obj) {
      if (obj.device === this.deviceUnique) {
        // Backup command returns with success or error
        if (obj.subcommand === 'backup') {
          if (obj.success) {
            this.$noty.success('Completed backup!');
            this.status = null;
            this.$nextTick(() => {
              this.$root.$emit('reloadDevice');
            });
          } else {
            this.$noty.warning(obj.error);
            this.status = null;
          }

          // Backup started.
        } else if (obj.subcommand === 'backup:start') {
          this.status = 'backup:start';
          if (!this.restic.run) {
            this.resticData.run = {};
          }
          this.resticData.run.start = Date.now();
          this.resticData.run.end = null;
        }
      }
    },
    calculateBackupDuration(lastRun) {
      const endDate = this.$date(lastRun.end);
      const startDate = this.$date(lastRun.start);

      const diff = endDate.diff(startDate);

      if (diff < 5000) {
        return `${diff} ms`;
      }
      if (diff < 120000) {
        return `${endDate.diff(startDate, 'second')} second(s)`;
      }
      return `${endDate.diff(startDate, 'minute')} minute(s)`;
    },
    nextRun(lastRun) {
      // try to determine the next run
      const date = this.$date(lastRun.start);
      let next = date.add(this.$date.duration(this.restic.schedule.interval, 's'));

      // if next < now(), next = now() + 60s
      const now = this.$date();
      if (next.unix() < now.unix()) {
        next = now.add(this.$date.duration(60, 's'));
      }

      return `${next.format('LLL')} (${next.fromNow()})`;
    },
  },
  mounted() {
    this.$root.$on('resticResponse', this.handleResticResponse);
    this.initBackupStatus();
  },
  beforeDestroy() {
    this.$root.$off('resticResponse', this.handleResticResponse);
  },
};
</script>

<style scoped lang="scss">
.status-message {
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 6px;
  margin-bottom: 4px;
}
</style>
