








import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapMutations } from 'vuex';
import ValueSwitch from '../Switch/ValueSwitch.vue';
import Theme from '../../models/enums/Theme';

@Component({
  name: 'ThemeSwitch',
  components: {
    ValueSwitch,
  },
  computed: {
    ...mapGetters('theme', { selectedTheme: 'selectedTheme' }),
  },
  methods: {
    ...mapMutations('theme', ['changeTheme']),
  },
})
export default class App extends Vue {
  selectedTheme!: Theme;

  changeTheme!: any;

  handleThemeSelect() {
    if (this.selectedTheme === Theme.Dark) {
      this.changeTheme(Theme.Light);
    } else {
      this.changeTheme(Theme.Dark);
    }
  }
}
