<template>
  <div class="main-gauge">
    <div class="gauge-element">
      <canvas ref="gauge" height="400" />
    </div>
    <div class="gauge-value">{{ parseInt(value) }}{{ noPercent ? '' : '%' }}</div>
    <div class="gauge-title">{{ title }}</div>
  </div>
</template>
<script>
import Chart from 'chart.js';

export default {
  name: 'gauge',
  data() {
    return {
      gauge: null,
    };
  },
  watch: {
    value(newVal) {
      if (newVal && !Number.isNaN(newVal)) {
        this.updateChart(parseInt(newVal, 10));
      }
    },
  },
  props: ['value', 'title', 'noPercent'],
  methods: {
    setupChart() {
      const gaugeCtx = this.$refs.gauge.getContext('2d');
      this.gauge = new Chart(gaugeCtx, {
        type: 'doughnut',
        data: {
          labels: ['Used', 'Free'],
          datasets: [
            {
              label: '%',
              data: [0, 100],
              backgroundColor: ['#F8B36B', '#575d61'],
              borderWidth: 0,
            },
          ],
        },
        options: {
          rotation: 1 * Math.PI,
          circumference: 1 * Math.PI,
          responsive: true,
          tooltips: {
            enabled: false,
          },
          legend: {
            display: false,
          },
          hover: {
            mode: null,
          },
        },
      });
    },
    updateChart(value) {
      this.gauge.data.datasets.map((dataset) => {
        // eslint-disable-next-line no-param-reassign
        let color = '';
        if (value < 60) {
          color = ['#4dbd74', '#575d61'];
        } else if (value < 80) {
          color = ['#F8B36B', '#575d61'];
        } else {
          color = ['#f86c6b', '#575d61'];
        }
        return Object.assign(dataset, { data: [value, 100 - value], backgroundColor: color });
      });
      this.gauge.update();
    },
  },
  async mounted() {
    await this.setupChart();
    if (this.value && !Number.isNaN(this.value)) {
      this.updateChart(parseInt(this.value, 10));
    }
  },
};
</script>

<style scoped>
.main-gauge {
  overflow: hidden;
  position: relative;
}

.gauge-title {
  padding-top: 6px;
  text-align: center;
  color: var(--font-color-secondary);
}

.gauge-value {
  color: var(--font-color-secondary);
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-top: -30%;
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .gauge-value {
    font-size: 14px;
  }
}

@media screen and (min-width: 1700px) {
  .main-gauge {
    padding-top: 0;
  }
}
</style>
