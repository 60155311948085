<template>
  <div class="property">
    <div class="title">
      {{ title }}
    </div>
    <div class="value">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Property',
  props: {
    title: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.property {
  margin-top: 10px;

  .value {
    color: var(--secondary-font-color);
  }

  .title {
    font-size: 9px;
    opacity: 0.8;
    text-transform: uppercase;
    margin-bottom: 2px;
  }
}
</style>
