<template>
  <card title="Application">
    <TextProperty
      title="MyGarageApp Version"
      v-if="stats !== undefined && stats.myGarageApp.version !== undefined"
      :value="stats.myGarageApp.version"
    />
    <TextProperty
      title="MyGarageApp is running"
      v-if="stats !== undefined && stats.myGarageApp.isRunning !== undefined"
      :value="stats.myGarageApp.isRunning ? 'Yes' : 'No'"
    />
    <TextProperty
      v-if="stats !== undefined && stats.displays !== undefined"
      title="Connected Display(s)"
      :value="stats.displays"
    />
  </card>
</template>
<script>
import Card from '../Card/Card.vue';
import TextProperty from '../Property/TextProperty.vue';

export default {
  name: 'MyGarageStats',
  props: ['stats'],
  components: {
    Card,
    TextProperty,
  },
  methods: {},
};
</script>
