











import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import AlertDropdown from './components/AlertManager/AlertDropdown.vue';
import Utils from './utils';
import ThemeSwitch from './components/ThemeSwitch/ThemeSwitch.vue';
import Theme from './models/enums/Theme';
import TheMenu from './components/TheMenu/TheMenu.vue';

@Component({
  name: 'app',
  data() {
    return {
      user: null,
      expandUser: false,
      showDropdown: false,
      hasAlerts: false,
      tokenInfo: {},
    };
  },
  components: {
    AlertDropdown,
    ThemeSwitch,
    TheMenu,
  },
  computed: {
    ...mapGetters('theme', { selectedTheme: 'selectedTheme' }),
  },
  methods: {
    userCan(perm: string, resource: string) {
      return Utils.userCan(perm, resource);
    },
    isAdmin() {
      return Utils.isAdmin();
    },
    handleAlerts(hasAlerts) {
      this.$data.hasAlerts = hasAlerts;
    },
  },
})
export default class App extends Vue {
  selectedTheme!: Theme;

  interval: NodeJS.Timeout | null = null;

  mounted() {
    this.$data.user = Utils.getUser();

    this.$root.$on('login', () => {
      this.$data.user = Utils.getUser();
    });
    this.$root.$on('logout', () => {
      this.$data.user = Utils.getUser();
    });
    this.$root.$on('closeAlerts', () => {
      this.$data.showDropdown = false;
    });

    this.loadTokenInfo().then(() => {
      if (this.tokenNeedsRefreshing()) {
        this.extendLogin();
      }
      this.interval = setInterval(() => {
        if (this.tokenNeedsRefreshing()) {
          this.extendLogin();
        }
      }, 60000);
    });
  }

  async extendLogin() {
    const response = await this.$utils.http().get('/api/v1/auth/extend');
    localStorage.setItem('jwt', response.data.token);
  }

  async loadTokenInfo() {
    const response = await this.$utils.http().get('/api/v1/auth/info');
    this.$data.tokenInfo = response.data.info;
  }

  tokenNeedsRefreshing() {
    const user = Utils.getUser();
    if (user && user.id) {
      // If token expires after 20 minutes and can be renewed after 15 minutes
      // then we have a renew window of 5 minutes - or 300 seconds.
      const tokenRenewWindow = (this.$data.tokenInfo.expire - this.$data.tokenInfo.renew) * 60;

      const nowUnixTS = Date.now() / 1000;
      if (nowUnixTS > user.exp - tokenRenewWindow && nowUnixTS < user.exp) {
        return true;
      }
    }
    return false;
  }

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
