<template>
  <card title="MagicInfo Stats">
    <TextProperty
      title="MagicInfo Premium Version"
      v-if="device.stats && device.stats.magicinfo && device.stats.magicinfo.config"
      :value="device.stats.magicinfo.config.premiumVersion"
    />
    <TextProperty
      title="MagicInfo Server Is Running"
      v-if="device.stats && device.stats.magicinfo"
      :value="device.stats.magicinfo.isRunning ? 'Yes' : 'No'"
    />
    <Property
      title="Service started"
      v-if="device.stats && device.stats.magicinfo && device.stats.magicinfo.startTime"
    >
      {{
        $date(new Date(device.stats.magicinfo.startTime)).format('LLL') +
        ' (' +
        $date(new Date(device.stats.magicinfo.startTime)).fromNow() +
        ')'
      }}
    </Property>
    <TextProperty
      title="Web Interface Was Accessible"
      v-if="device.stats && device.stats.magicinfo && device.stats.magicinfo.lastWebContact"
      :value="$date(new Date(device.stats.magicinfo.lastWebContact)).fromNow()"
    />
    <TextProperty
      title="WRSM URL"
      :monospace="true"
      v-if="device.stats && device.stats.magicinfo && device.stats.magicinfo.config"
      :value="device.stats.magicinfo.config.wsrmUrl"
    />
    <TextProperty
      title="Web URL"
      :isLink="true"
      :monospace="true"
      v-if="device.stats && device.stats.magicinfo && device.stats.magicinfo.config"
      :value="device.stats.magicinfo.config.webUrl"
    />
    <TextProperty
      title="Contents Home"
      :monospace="true"
      v-if="device.stats && device.stats.magicinfo && device.stats.magicinfo.config"
      :value="device.stats.magicinfo.config.contentsHome"
    />
    <TextProperty
      title="MagicInfo Service User"
      v-if="device.stats && device.stats.magicinfo && device.stats.magicinfo.serviceUser"
      :value="device.stats.magicinfo.serviceUser"
    />
  </card>
</template>

<script>
import TextProperty from '../Property/TextProperty.vue';
import Property from '../Util/Property.vue';
import Card from '../Card/Card.vue';

export default {
  name: 'MagicInfoServerStats',
  props: ['device'],
  components: {
    TextProperty,
    Property,
    Card,
  },
  methods: {},
};
</script>

<style scoped lang="text/scss"></style>
