<template>
  <div class="login-frame-wrapper">
    <div class="login-frame">
      <h1>Login</h1>
      <h3 class="error" v-if="isError">There was an error logging you in. Please try again.</h3>
      <card>
        <loader v-if="isBusy" />
        <div class="properties">
          <div class="property">
            <label for="login">Email address</label>
            <input
              id="login"
              type="email"
              class="input-text"
              v-model="login"
              required
              autofocus
              placeholder="Email address"
            />
          </div>
          <div class="property">
            <label for="password">Password</label>
            <input
              id="password"
              type="password"
              class="input-text"
              v-model="password"
              required
              placeholder="Password"
              @keyup="isEnter"
            />
          </div>
          <div class="property">
            <base-button
              type="THEMED"
              v-on:click="$emit('handleSubmit', login, password)"
              title="Login"
            />
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Card from '../Card/Card.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import Loader from '../Loader/Loader.vue';

export default {
  name: 'LoginBox',
  components: {
    Card,
    BaseButton,
    Loader,
  },
  data() {
    return {
      login: '',
      password: '',
      error: false,
    };
  },
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isEnter(e) {
      if (e.key === 'Enter') {
        this.$emit('handleSubmit', this.login, this.password);
      }
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-bottom: 4px;
}
.login-frame-wrapper {
  width: 100%;
  padding-right: 50px;
  justify-content: center;
  display: flex;
  box-sizing: border-box;
}
.login-frame {
  margin-top: 100px;
  width: 400px;
}
.error {
  color: #e13e3d;
  font-weight: normal;
}
</style>
