<template>
  <div class="tabs">
    <ul class="tab-control">
      <li
        v-for="(tab, index) in tabs"
        :key="tab.title"
        @click="selectTab(index)"
        v-bind:class="{ active: index == selectedIndex }"
      >
        {{ tab.title }}
      </li>
    </ul>
    <div class="tab-content">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Tabs',
  data() {
    return {
      selectedIndex: 0,
      tabs: [],
    };
  },
  created() {
    this.tabs = this.$children;
  },
  methods: {
    selectTab(index) {
      this.tabs[this.selectedIndex].isActive = false;
      this.selectedIndex = index;
      this.tabs[index].isActive = true;
    },
  },
  mounted() {
    this.tabs[0].isActive = true;
  },
};
</script>

<style scoped lang="scss">
.tabs {
  width: 100%;
  box-sizing: border-box;
}
.tab-control {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  cursor: pointer;
  .active {
    background: var(--tab-active-color);
  }
  li {
    transition: all 0.2s ease;
    font-size: 14px;
    background: var(--tab-color);
    padding: 12px 16px;
  }
}
.tab-content {
  width: 100%;
  padding: 16px;
  background: var(--tab-active-color);
  box-sizing: border-box;
}
</style>
