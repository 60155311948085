import Vue from 'vue';
import Vuex from 'vuex';
import alertQuery from './modules/AlertQuery';
import theme from './modules/Theme';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alertQuery,
    theme,
  },
});
