<template>
  <div v-if="show">
    <div class="overlay" @click="close()" />
    <div class="modal">
      <card title="Active Processes">
        <div class="card-content">
          <table class="table">
            <thead>
              <tr>
                <th>PID</th>
                <th>User</th>
                <th>Program</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="proc in device.stats.processes" :key="proc.pid">
                <td>
                  {{ proc.pid }}
                </td>
                <td>
                  {{ proc.user }}
                </td>
                <td>
                  {{ proc.program }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-buttons">
          <base-button type="THEMED" v-on:click="close()" title="Close" />
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Card from '../../Card/Card.vue';
import BaseButton from '../../BaseButton/BaseButton.vue';

export default {
  name: 'Processes',
  props: ['device', 'show'],
  components: {
    Card,
    BaseButton,
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 30;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  z-index: 31;
}

.card-content {
  overflow: auto;
  max-height: 300px;
}

.card-buttons {
  margin-top: 25px;
}

table.table {
  margin-top: 10px;
  width: 100%;
  border-collapse: collapse;

  tr {
    height: 30px;
  }

  th,
  td {
    padding: 0 10px;
  }

  thead tr th {
    text-align: left;
    background: rgba(255, 255, 255, 0.04);
  }

  tbody tr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.04);
  }

  th {
    font-weight: normal;
  }
}
</style>
