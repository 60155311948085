import { render, staticRenderFns } from "./ScriptView.vue?vue&type=template&id=52ad2996&scoped=true&"
import script from "./ScriptView.vue?vue&type=script&lang=js&"
export * from "./ScriptView.vue?vue&type=script&lang=js&"
import style0 from "./ScriptView.vue?vue&type=style&index=0&id=52ad2996&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.6_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ad2996",
  null
  
)

export default component.exports