<template>
  <div>
    <div class="card-title">View script output</div>
    <select name="status-online" class="input-select" v-model="selectedScript">
      <option value="">---</option>
      <option v-for="script in Object.values(scripts)" :value="script._id" :key="script._id">
        {{ script.description }}
      </option>
    </select>
    <div class="toggle-break"><span>Break output</span><value-switch @changed="breakOutput" /></div>
    <table-component
      v-if="tableData"
      :enable-column-filter="true"
      :columns="tableColumns"
      :table-data="tableData"
      :showStatus="true"
    />
  </div>
</template>
<script>
import ValueSwitch from '../Switch/ValueSwitch.vue';
import TableComponent from '../Table/TableComponent.vue';

export default {
  name: 'ScriptView',
  components: {
    TableComponent,
    ValueSwitch,
  },
  props: ['devices'],
  data() {
    return {
      scripts: [],
      selectedScript: '',
      scriptStatusList: {},
      tableColumns: [],
    };
  },
  watch: {
    selectedScript() {
      this.getScriptStatus();
    },
  },

  methods: {
    async getScripts() {
      this.scripts = await this.$utils
        .http()
        .get('/api/v1/scripts')
        .then((res) => res.data);
    },
    async getScriptStatus() {
      await this.$utils
        .http()
        .post(`/api/v1/scripts/filtered/${this.selectedScript}`, {
          filter: this.devices.map((device) => device._id),
        })
        .then((res) => {
          this.scriptStatusList = res.data;
        });
    },
    breakOutput() {
      const stdErrItem = this.tableColumns.find((column) => column.key === 'stdOut');
      const stdOutItem = this.tableColumns.find((column) => column.key === 'stdErr');
      if (!stdErrItem.breakValue) {
        this.$set(
          this.tableColumns,
          this.tableColumns.findIndex((column) => column.key === 'stdOut'),
          { ...stdErrItem, breakValue: true },
        );
        this.$set(
          this.tableColumns,
          this.tableColumns.findIndex((column) => column.key === 'stdErr'),
          { ...stdOutItem, breakValue: true },
        );
      } else {
        this.$set(
          this.tableColumns,
          this.tableColumns.findIndex((column) => column.key === 'stdOut'),
          { ...stdErrItem, breakValue: false },
        );
        this.$set(
          this.tableColumns,
          this.tableColumns.findIndex((column) => column.key === 'stdErr'),
          { ...stdOutItem, breakValue: false },
        );
      }
    },
  },
  computed: {
    tableData() {
      const newData = [];
      this.devices.forEach((device) => {
        newData.push({
          ...this.scriptStatusList[device._id],
          ...{
            deviceName: device.name,
          },
        });
      });
      return newData;
    },
  },
  mounted() {
    this.getScripts();
    this.tableColumns = [
      {
        title: 'Name',
        key: 'deviceName',
      },
      {
        title: 'Exit code',
        key: 'exitCode',
      },
      {
        title: 'std Err',
        key: 'stdErr',
        breakValue: false,
      },
      {
        title: 'std Out',
        key: 'stdOut',
        breakValue: false,
      },
    ];
  },
};
</script>

<style scoped lang="scss">
.toggle-break {
  margin: 14px 0;
  width: 200px;
  display: flex;
  align-items: center;

  span {
    margin-right: 8px;
  }
}
</style>
