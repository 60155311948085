<template>
  <div
    class="checkbox"
    @click.stop.prevent="handleCheckClick()"
    v-bind:class="{ active: internalValue }"
  >
    <input v-show="false" type="checkbox" ref="chbx" :value="internalValue" />
    <span v-show="this.internalValue"><i class="uil uil-check" /></span>
  </div>
</template>

<script>
export default {
  name: 'CheckboxProperty',
  props: ['value', 'data-true-value', 'data-false-value'],
  data() {
    return {
      internalValue: false,
    };
  },
  watch: {
    value() {
      if (typeof this.value !== 'undefined') {
        if (typeof this.value === 'boolean') {
          this.internalValue = this.value;
          return;
        }
        if (
          typeof this.dataTrueValue !== 'undefined' &&
          typeof this.dataFalseValue !== 'undefined'
        ) {
          if (this.value === this.dataTrueValue) {
            this.internalValue = true;
            return;
          }
        }
      }
      this.internalValue = false;
    },
  },
  methods: {
    handleCheckClick() {
      this.internalValue = !this.internalValue;
      if (typeof this.dataTrueValue !== 'undefined' && typeof this.dataFalseValue !== 'undefined') {
        if (this.internalValue) {
          this.$emit('input', this.dataTrueValue);
        } else {
          this.$emit('input', this.dataFalseValue);
        }
      } else {
        this.$emit('input', this.internalValue);
      }
    },
  },
  mounted() {
    if (typeof this.value !== 'undefined') {
      if (typeof this.value === 'boolean') {
        this.internalValue = this.value;
      } else if (
        typeof this.dataTrueValue !== 'undefined' &&
        typeof this.dataFalseValue !== 'undefined'
      ) {
        if (this.value === this.dataTrueValue) {
          this.internalValue = true;
        }
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.checkbox {
  height: 12px;
  width: 12px;
  background: var(--checkbox-background-color);
  border: 1px solid #2e3546;
  cursor: pointer;
  line-height: 12px;
  overflow: hidden;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    background: rgba(255, 255, 255, 0.3);
  }

  span {
    margin: 0;
    padding: 0;
    font-size: 15px;
    color: var(--checkbox-font-color);
    position: relative;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
}
</style>
