<template>
  <div class="property">
    <div class="title">
      {{ title }}
    </div>
    <div class="value" v-bind:class="{ monospace }" v-html="value" v-if="!isLink" />
    <a
      :href="value"
      target="_blank"
      class="value"
      style="display: block"
      v-bind:class="{ monospace }"
      v-html="value"
      v-else
    />
    <slot name="extra" />
  </div>
</template>

<script>
export default {
  name: 'TextProperty',
  props: {
    title: String,
    value: String,
    monospace: {
      type: Boolean,
      default: false,
    },
    isLink: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.property {
  margin-top: 10px;

  .value {
    color: var(--secondary-font-color);
  }

  .title {
    font-size: 10px;
    opacity: 0.8;
    text-transform: uppercase;
  }
}
</style>
