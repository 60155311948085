<template>
  <card title="YoYo License Status" v-if="license !== null">
    <TextProperty title="License Key" :value="license.key" />
    <TextProperty title="License Holder" :value="license.client + ' (' + license.name + ')'" />
    <TextProperty title="Valid Until" :value="$date(new Date(license.endDate)).format('LLL')" />
  </card>
  <card title="YoYo License Status" v-else>
    <div>No License Found...</div>
  </card>
</template>

<script>
import Utils from '@/utils';
import TextProperty from '../Property/TextProperty.vue';
import Card from '../Card/Card.vue';

export default {
  name: 'LicenseStatus',
  components: {
    Card,
    TextProperty,
  },
  props: ['macaddress'],
  data() {
    return {
      license: null,
    };
  },
  watch: {
    macaddress() {
      this.loadLicense();
    },
  },
  mounted() {
    this.loadLicense();
  },
  methods: {
    async loadLicense() {
      const response = await Utils.fetch(
        `https://yoyo.screencom.eu/license/info/${this.macaddress}`,
        {},
        this,
      ).then((res) => res.json());
      if (response.success) {
        this.license = response.licenseInfo;
      }
    },
  },
};
</script>
