/* eslint-disable no-undef */
import axios from 'axios';
import Vue from 'vue';

const utils = {
  isLoggedIn: () => {
    const user = util.getUser();
    if (user != null) {
      if (user.exp * 1000 > Date.now()) {
        return true;
      }
    }
    return false;
  },

  isAdmin: () => {
    const user = util.getUser();
    if (user != null) {
      if (user.exp * 1000 > Date.now()) {
        return user.access >= 100;
      }
    }
    return false;
  },

  getUser: () => {
    const jwt = window.localStorage.getItem('jwt');
    if (jwt && jwt !== 'null') {
      return JSON.parse(atob(jwt.split('.')[1]));
    }
    return null;
  },

  http() {
    const httpClient = axios.create();
    const token = `JWT ${window.localStorage.getItem('jwt')}`;
    httpClient.defaults.timeout = 5000;

    // Intercept a 401 response and redirect to the logout/login page.
    httpClient.interceptors.response.use(undefined, (err) => {
      const error = err.response;
      if (error && error.status === 401) {
        window.location.href = '/logout';
        return;
      }

      throw err;
    });

    if (token !== null) {
      httpClient.defaults.headers.common = {
        'X-Access-Token': token,
      };
    }

    return httpClient;
  },
};
// eslint-disable-next-line import/prefer-default-export
export { utils };

Object.defineProperties(Vue.prototype, {
  $utils: {
    get() {
      return utils;
    },
  },
});
