<template>
  <div v-if="device && device.stats">
    <card title="Stats">
      <div class="properties">
        <ProgressProperty
          v-if="typeof device.stats.percMemory !== 'undefined'"
          title="Memory Usage"
          :value="device.stats.percMemory.toString()"
          :description="
            Math.round(device.stats.percMemory) + '% of ' + bytesToSize(device.stats.totalMemory)
          "
        />

        <div v-if="device.stats.disks">
          <ProgressProperty
            v-for="disk in device.stats.disks"
            :key="disk.device"
            :title="`Disk Usage ${disk.device} (${disk.fstype})`"
            :value="disk.percent.toString()"
            :description="Math.round(disk.percent) + '% of ' + bytesToSize(disk.total)"
          />
        </div>
        <div v-else>
          <ProgressProperty
            v-if="typeof device.stats.percDisk !== 'undefined'"
            title="Disk Usage"
            :value="device.stats.percDisk.toString()"
            :description="
              Math.round(device.stats.percDisk) + '% of ' + bytesToSize(device.stats.totalDisk)
            "
          />
        </div>

        <div v-if="typeof device.stats.cpuUtilization !== 'undefined'">
          <ProgressProperty
            v-for="(cpu, index) in device.stats.cpuUtilization"
            v-bind:key="index"
            :title="`CPU #${index + 1} Usage`"
            :value="cpu"
            :description="cpu"
            :high="60"
            :critical="80"
          />
        </div>

        <ProgressProperty
          v-if="
            typeof device.stats.temperature !== 'undefined' &&
            typeof device.stats.temperature.current !== 'undefined' &&
            device.stats.temperature.current !== 0
          "
          :title="`CPU Temperature`"
          :value="device.stats.temperature.current.toString()"
          :description="device.stats.temperature.current + ' °C'"
          :high="60"
          :critical="80"
        />

        <ProgressProperty
          v-if="
            device.system.os == 'magicinfo' &&
            typeof device.magicinfo.stats.temperature !== 'undefined'
          "
          :title="`Display Temperature`"
          :value="device.magicinfo.stats.temperature.toString()"
          :description="device.magicinfo.stats.temperature + ' °C'"
          :high="60"
          :critical="80"
        />
      </div>
    </card>
  </div>
</template>
<script>
import ProgressProperty from '../../Property/ProgressProperty.vue';
import Card from '../../Card/Card.vue';

export default {
  props: ['device'],
  components: {
    ProgressProperty,
    Card,
  },
  methods: {
    bytesToSize(bytes) {
      if (!Number.isNaN(bytes)) {
        // eslint-disable-next-line no-param-reassign
        bytes = parseInt(bytes, 10);
      } else {
        return '-';
      }
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
      if (i !== 0) {
        return `${Math.round((bytes / 1000 ** i) * 100) / 100} ${sizes[i]}`;
      }
      return `${Math.round(bytes / 1000 ** i)} ${sizes[i]}`;
    },
  },
};
</script>
