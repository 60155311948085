<script>
import Utils from '@/utils';

export default {
  name: 'Base',
  methods: {
    isAdmin() {
      return Utils.isAdmin();
    },
    User() {
      return Utils.getUser();
    },
  },
};
</script>

<style scoped></style>
