var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.device && _vm.device.stats)?_c('div',[_c('card',{attrs:{"title":"Stats"}},[_c('div',{staticClass:"properties"},[(typeof _vm.device.stats.percMemory !== 'undefined')?_c('ProgressProperty',{attrs:{"title":"Memory Usage","value":_vm.device.stats.percMemory.toString(),"description":Math.round(_vm.device.stats.percMemory) + '% of ' + _vm.bytesToSize(_vm.device.stats.totalMemory)}}):_vm._e(),(_vm.device.stats.disks)?_c('div',_vm._l((_vm.device.stats.disks),function(disk){return _c('ProgressProperty',{key:disk.device,attrs:{"title":("Disk Usage " + (disk.device) + " (" + (disk.fstype) + ")"),"value":disk.percent.toString(),"description":Math.round(disk.percent) + '% of ' + _vm.bytesToSize(disk.total)}})}),1):_c('div',[(typeof _vm.device.stats.percDisk !== 'undefined')?_c('ProgressProperty',{attrs:{"title":"Disk Usage","value":_vm.device.stats.percDisk.toString(),"description":Math.round(_vm.device.stats.percDisk) + '% of ' + _vm.bytesToSize(_vm.device.stats.totalDisk)}}):_vm._e()],1),(typeof _vm.device.stats.cpuUtilization !== 'undefined')?_c('div',_vm._l((_vm.device.stats.cpuUtilization),function(cpu,index){return _c('ProgressProperty',{key:index,attrs:{"title":("CPU #" + (index + 1) + " Usage"),"value":cpu,"description":cpu,"high":60,"critical":80}})}),1):_vm._e(),(
          typeof _vm.device.stats.temperature !== 'undefined' &&
          typeof _vm.device.stats.temperature.current !== 'undefined' &&
          _vm.device.stats.temperature.current !== 0
        )?_c('ProgressProperty',{attrs:{"title":"CPU Temperature","value":_vm.device.stats.temperature.current.toString(),"description":_vm.device.stats.temperature.current + ' °C',"high":60,"critical":80}}):_vm._e(),(
          _vm.device.system.os == 'magicinfo' &&
          typeof _vm.device.magicinfo.stats.temperature !== 'undefined'
        )?_c('ProgressProperty',{attrs:{"title":"Display Temperature","value":_vm.device.magicinfo.stats.temperature.toString(),"description":_vm.device.magicinfo.stats.temperature + ' °C',"high":60,"critical":80}}):_vm._e()],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }