<template>
  <div class="property">
    <div class="title">
      <div style="float: right">{{ description }}</div>
      {{ title }}
    </div>
    <div class="bar">
      <div ref="val" class="value" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressProperty',
  props: {
    title: String,
    value: String,
    high: {
      type: Number,
      default: 60,
    },
    critical: {
      type: Number,
      default: 80,
    },
    description: {
      type: String,
      default: '',
    },
  },
  watch: {
    value(newVal) {
      this.updateValues(newVal, this.color);
    },
    color(newVal) {
      this.updateValues(this.value, newVal);
    },
  },
  methods: {
    updateValues() {
      if (this.value.toString().indexOf('%') !== -1) {
        this.$refs.val.style.width = this.value;
      } else {
        this.$refs.val.style.width = `${this.value}%`;
      }

      if (!Number.isNaN(this.value.replace('%', ''))) {
        const i = parseFloat(this.value.replace('%', ''));
        if (i >= this.critical) {
          this.$refs.val.style.background = '#f86c6b';
        } else if (i >= this.high) {
          this.$refs.val.style.background = '#f8b36b';
        } else {
          this.$refs.val.style.background = '#4dbd74';
        }
      }
    },
  },
  mounted() {
    this.updateValues(this.value, this.color);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.property {
  margin-top: 10px;
  margin-bottom: 15px;

  .bar {
    background: var(--bar-inner-color);
    height: 5px;
    border-radius: 2px;
    overflow: hidden;
    margin-top: 10px;

    .value {
      height: 100%;
      width: 0;
      transition: 0.5s;
      -webkit-transition: 0.5s;
    }
  }

  .title {
    font-size: 10px;
    opacity: 0.8;
    text-transform: uppercase;
  }
}
</style>
