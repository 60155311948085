





















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import OperatingSystem from '../../models/enums/OperatingSystem';

@Component({
  name: 'OSLogo',
})
export default class OSLogo extends Vue {
  OperatingSystem: any = OperatingSystem;

  @Prop({ default: '' }) readonly operatingSystem!: OperatingSystem;
}
