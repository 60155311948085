























































































































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'BaseLogo',
})
export default class BaseLogo extends Vue {}
