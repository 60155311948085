<template>
  <div class="command-table">
    <table>
      <thead>
        <tr>
          <th>Script Name</th>
          <th>OK</th>
          <th>Not OK</th>
          <th>Not run</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="command in commands" :key="command.description">
          <td>{{ command.description }}</td>
          <td class="ok">{{ command.ok }}</td>
          <td class="notok">{{ command.notok }}</td>
          <td class="unkn">{{ command.unkn }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Commands',
  data() {
    return {
      commands: [],
    };
  },
  methods: {
    async getCommands() {
      const response = await this.$utils.http().get('/api/v1/stats/commands');
      this.commands = response.data;
    },
  },
  mounted() {
    this.getCommands();
  },
};
</script>

<style scoped lang="scss">
.command-table {
  overflow-x: auto;
  padding-bottom: 30px;
}

.command-table table {
  width: 100%;
  border-collapse: collapse;

  .loader {
    text-align: center;
    padding: 20px 0;

    img {
      height: 30px;
    }
  }

  tr {
    height: 30px;
  }

  tbody tr {
    &.selected {
      background: var(--table-row-hover-color);
    }

    &:hover {
      background: var(--table-row-hover-color);
    }
  }

  th,
  td {
    padding: 0 10px;
  }

  thead tr th {
    //border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
    background: var(--table-header-color);
  }

  th {
    font-weight: bold;
  }

  .ok {
    font-weight: bold;
    color: #4dbd74;
  }

  .notok {
    font-weight: bold;
    color: #f86c6b;
  }

  .unkn {
    font-weight: bold;
    color: #f8b36b;
  }
}
</style>
