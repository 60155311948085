import Noty from 'noty';
import _Vue from 'vue';

const defaultOptions = {
  layout: 'topRight',
  theme: 'mint',
  timeout: 5000,
  progressBar: true,
  closeWith: ['click'],
};

export const VueNoty = {
  options: {},

  setOptions(options?: Noty.Options): void {
    this.options = { ...defaultOptions, ...options };
  },

  create(params?: Noty.Options): Noty {
    return new Noty(params);
  },

  show(text: string, type: Noty.Type = 'alert', options?: Noty.Options): Noty {
    const params: Noty.Options = {
      ...this.options,
      ...options,
      type,
      text,
    };

    const noty = this.create(params);
    noty.show();
    return noty;
  },

  success(text: string, options?: Noty.Options): Noty {
    return this.show(text, 'success', options);
  },

  error(text: string, options?: Noty.Options): Noty {
    return this.show(text, 'error', options);
  },

  warning(text: string, options?: Noty.Options): Noty {
    return this.show(text, 'warning', options);
  },

  info(text: string, options?: Noty.Options): Noty {
    return this.show(text, 'info', options);
  },
};

export default function NotyPlugin(Vue: typeof _Vue, options?: Noty.Options) {
  VueNoty.setOptions(options);
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$noty = VueNoty;
}
