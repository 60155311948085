<template>
  <label class="switch" v-bind:class="{ open: isSuccess }">
    <input :checked="enabled || isSuccess" type="checkbox" @change="$emit('changed')" />
    <span v-bind:class="{ 'two-colored': useTwoColored }" class="slider round" />
  </label>
</template>
<script>
export default {
  name: 'ValueSwitch',
  props: {
    isSuccess: {
      type: Boolean,
      default: false,
    },
    useTwoColored: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
/* The switch - the box around the slider */
.switch {
  margin: 0;
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--switch-background);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 0 1px #72818e;
}

.two-colored {
  background-color: #f7b36b;
}
input:checked + .slider {
  background-color: #20a7d8;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch.open input:checked + .slider {
  background-color: #3a9d5d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3a9d5d;
}
</style>
