<template>
  <div v-show="isActive"><slot /></div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    title: String,
  },
  data() {
    return {
      isActive: false,
    };
  },
};
</script>
