<template>
  <div class="device-viewer" v-if="devices.length !== 0">
    <h1 class="title">Manage Multiple Devices</h1>
    <h4 class="sub-name">{{ devices.length }} devices selected</h4>
    <tabs class="action-tabs">
      <tab title="Actions">
        <div class="card-title">Action</div>
        <select name="status-online" class="input-select" v-model="action">
          <option value="">---</option>
          <optgroup label="Tags">
            <option value="tag:add">Add a tag</option>
            <option value="tag:remove">Remove a tag</option>
          </optgroup>
          <optgroup label="Status">
            <option value="status:reboot">Reboot device</option>
            <option value="status:restart:wc">Restart Wherever</option>
            <option value="status:update:wc">Update Wherever</option>
          </optgroup>
        </select>
        <div v-if="action.startsWith('tag')" style="margin-top: 10px">
          <vue-tags-input
            class="tags-input"
            v-model="tag"
            :tags="tags"
            :autocomplete-min-length="0"
            :autocomplete-items="filteredTags"
            :add-only-from-autocomplete="true"
            placeholder="Enter one or more tags"
            @tags-changed="(newTags) => (tags = newTags)"
          />
        </div>
        <div class="property">
          <base-button
            v-on:click="executeAction"
            type="THEMED"
            isSmall
            title="Execute on selection"
          />
          <table-component
            :tableData="devices"
            :columns="overviewColumns"
            :show-online-status="true"
            :showTags="true"
          />
        </div>
      </tab>
      <tab title="View script info"><script-view :devices="devices" /> </tab>
    </tabs>
    <div class="left">
      <base-button
        type="THEMED"
        isSmall
        title="Clear selection"
        v-on:click="handleClearSelection"
      />
    </div>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>
<script>
import Utils from '@/utils';
import VueTagsInput from '@johmun/vue-tags-input';
import Tabs from '../Tabs/Tabs.vue';
import Tab from '../Tabs/Tab.vue';
import ScriptView from './ScriptView.vue';
import TableComponent from '../Table/TableComponent.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'MultiOverview',
  components: {
    TableComponent,
    VueTagsInput,
    ScriptView,
    BaseButton,
    Tabs,
    Tab,
  },
  computed: {
    filteredTags() {
      return this.autocompleteItems.filter(
        (i) => i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1,
      );
    },
  },
  props: ['deviceIds'],
  data() {
    return {
      ajaxCompleted: false,
      devices: [],
      action: '',
      tag: '',
      tags: [],
      autocompleteItems: [],
      webSocket: null,
      scriptStatusList: [],
      overviewColumns: [
        {
          title: 'Name',
          key: 'name',
        },
      ],
    };
  },
  watch: {
    deviceIds() {
      this.getDevices();
    },
  },
  methods: {
    async getDevices() {
      const response = await Utils.fetch(
        '/api/v1/devices/filtered',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filter: this.deviceIds,
          }),
        },
        this,
      ).then((res) => res.json());
      if (response.success) {
        this.ajaxCompleted = true;
        this.devices = response.devices;
      }
    },
    handleClearSelection() {
      this.$emit('clear');
    },
    async getTags() {
      const response = await Utils.fetch('/api/v1/tags', {}, this).then((res) => res.json());
      if (response.length > 0) {
        this.autocompleteItems = response.sort().map((e) => ({ text: e }));
      }
    },
    async executeAction() {
      switch (this.action) {
        case 'tag:add':
          this.addTags();
          break;
        case 'tag:remove':
          this.removeTags();
          break;
        case 'status:reboot':
          this.rebootDevices();
          break;
        case 'status:update:wc':
          this.updateWherever();
          break;
        case 'status:restart:wc':
          this.restartWherever();
          break;
        default:
          break;
      }
    },
    async addTags() {
      if (this.tags.length > 0) {
        const response = await Utils.fetch(
          '/api/v1/devices/batch/tags',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              filter: this.devices.map((d) => d._id),
              tags: this.tags.map((t) => t.text),
            }),
          },
          this,
        ).then((res) => res.json());
        if (response.success) {
          this.getDevices();
          this.$noty.success('The new tag(s) were saved!');
        } else {
          this.$noty.warning(response.message);
        }

        this.tags = [];
      }
    },
    async removeTags() {
      if (this.tags.length > 0) {
        const response = await Utils.fetch(
          '/api/v1/devices/batch/tags',
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              filter: this.devices.map((d) => d._id),
              tags: this.tags.map((t) => t.text),
            }),
          },
          this,
        ).then((res) => res.json());
        if (response.success) {
          this.getDevices();
          this.$noty.success('The tag(s) were removed!');
        } else {
          this.$noty.warning(response.message);
        }

        this.tags = [];
      }
    },
    async rebootDevices() {
      const rebootDevicesCount = this.sendWebSocketMessage('reboot');

      if (rebootDevicesCount > 0) {
        this.$noty.success(`Sending reboot command to ${rebootDevicesCount} devices.`);
      }

      const displays = this.devices
        .filter((e) => e.system && e.system.os === 'magicinfo')
        .map((e) => e._id);
      if (displays.length > 0) {
        this.rebootDisplay(displays);
      }
    },
    async rebootDisplay(displays) {
      const response = await Utils.fetch(
        '/api/v1/miservers/display/reboot',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            devices: displays,
          }),
        },
        this,
      ).then((res) => res.json());

      if (response.success) {
        this.$noty.success(
          `Sending reboot command to ${response.successDevices.length} display(s).`,
        );
      } else {
        console.warn(response);
      }
    },
    sendWebSocketMessage(message) {
      const selection = this.devices
        .filter((d) => d.system && d.system.os !== 'magicinfo')
        .map((d) => d.hwUnique);
      for (let i = 0; i < selection.length; i += 1) {
        this.webSocket.send(
          JSON.stringify({
            type: message,
            target: selection[i],
            jwt: localStorage.getItem('jwt'),
          }),
        );
      }

      return selection.length;
    },
    async restartWherever() {
      const restartDevicesCount = this.sendWebSocketMessage('restart');

      if (restartDevicesCount > 0) {
        this.$noty.success(
          `Sending restart Wherever Client command to ${restartDevicesCount} devices.`,
        );
      } else {
        this.$noty.warning('No compatible devices found to send a reboot command to.');
      }
    },
    async updateWherever() {
      const updateDevicesCount = this.sendWebSocketMessage('update');

      if (updateDevicesCount > 0) {
        this.$noty.success(
          `Sending update Wherever Client command to ${updateDevicesCount} devices.`,
        );
      } else {
        this.$noty.warning('No compatible devices found to send a reboot command to.');
      }
    },
    startWebSocket() {
      let protocol = 'ws';
      if (window.location.protocol.substring(4, 5) === 's') {
        protocol += 's';
      }

      this.webSocket = new WebSocket(`${protocol}://${window.location.host}/api/ws`);
    },
    stopWebSocket() {
      if (this.webSocket !== null) {
        this.webSocket.close();
        this.webSocket = null;
      }
    },
  },
  mounted() {
    this.startWebSocket();
    this.getTags();
    this.getDevices();
  },
  beforeDestroy() {
    this.stopWebSocket();
  },
};
</script>
<style scoped lang="scss"></style>
