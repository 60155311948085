<template>
  <div>
    <card
      title="Content Preview"
      v-if="
        device.magicinfo.stats !== 'undefined' &&
        typeof device.magicinfo.stats.previewCapture === 'string'
      "
    >
      <div class="preview-holder">
        <div class="preview-spinner">
          <img :src="loadingImage" />
        </div>
        <!-- start loading Capture first, then replace it with Thumb-->
        <img
          ref="preview"
          :src="device.magicinfo.stats.previewCapture"
          :data-base-img="
            device.magicinfo.stats.previewThumb
              ? device.magicinfo.stats.previewThumb
              : device.magicinfo.stats.previewCapture
          "
          class="preview-img"
          @load="handleImageLoad"
        />
      </div>
    </card>
    <card title="MagicInfo Stats" v-if="device.magicinfo.stats !== undefined">
      <Property title="Server name">
        <router-link :to="`/miservers/${device.magicinfo.miserver._id}`">
          {{ device.magicinfo.miserver.name }}
        </router-link>
      </Property>

      <TextProperty title="Server version" :value="device.magicinfo.miserver.version" />
      <TextProperty
        title="Model"
        :value="`${device.magicinfo.system.deviceModelName} -
         ${device.magicinfo.system.deviceType}`"
      />
      <TextProperty
        title="Display"
        v-if="device.magicinfo.stats.screenSize && device.magicinfo.stats.resolution"
        :value="`${
          device.magicinfo.stats.screenSize
        }&quot; (${device.magicinfo.stats.resolution.replace('*', 'x')})`"
      />
      <TextProperty
        title="Firmware version"
        :value="device.magicinfo.stats.firmwareVersion"
        v-if="device.magicinfo.stats.firmwareVersion"
      />
      <TextProperty
        title="Device group"
        :value="device.magicinfo.stats.deviceGroupName"
        v-if="device.magicinfo.stats.deviceGroupName"
      />
      <TextProperty
        title="Schedule name"
        :value="device.magicinfo.stats.contentScheduleName"
        v-if="device.magicinfo.stats.contentScheduleName"
      />

      <TagProperty
        title="Variable tags"
        v-if="device.magicinfo.stats.tags && device.magicinfo.stats.tags.variable.length > 0"
        :value="device.magicinfo.stats.tags.variable"
      />
      <TagProperty
        title="Media tags"
        v-if="device.magicinfo.stats.tags && device.magicinfo.stats.tags.media.length > 0"
        :value="device.magicinfo.stats.tags.media"
      />
    </card>
  </div>
</template>

<script>
import TextProperty from '../Property/TextProperty.vue';
import TagProperty from '../Property/TagProperty.vue';
import Property from '../Util/Property.vue';
import Card from '../Card/Card.vue';

export default {
  name: 'MagicInfoServerStats',
  props: ['device'],
  components: {
    TextProperty,
    TagProperty,
    Property,
    Card,
  },
  data() {
    return {
      refreshInterval: null,
    };
  },
  watch: {
    device() {
      if (typeof this.$refs.preview !== 'undefined') {
        this.$refs.preview.style.opacity = '0';
      }
    },
  },
  methods: {
    refreshPreview() {
      if (typeof this.$refs.preview !== 'undefined') {
        const imgUrl = this.$refs.preview.getAttribute('data-base-img');
        this.$refs.preview.src = `${imgUrl}?${Date.now()}`;
      }
    },
    handleImageLoad() {
      this.$refs.preview.style.opacity = '1';
    },
  },
  mounted() {
    this.refreshInterval = setInterval(() => {
      this.refreshPreview();
    }, 30000);
  },
  beforeDestroy() {
    if (this.refreshInterval != null) {
      clearInterval(this.refreshInterval);
    }
  },
};
</script>
<style scoped lang="scss">
.preview-img {
  max-width: 100%;
  margin-top: 10px;
  border: 1px solid #23282c;
  position: relative;
}

.preview-holder {
  position: relative;
}

.preview-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  img {
    height: 30px;
  }
}
</style>
